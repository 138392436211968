import { filter, find, isEmpty, noop } from 'lodash/fp';
import React from 'react';

import {
  DeviceType,
  useSnoozeDevice,
  useSpaces,
  useUnsnoozeDevice,
  useWithSupportCenter,
} from '@portals/api/organizations';
import { ReactComponent as Danger } from '@portals/icons/linear/danger.svg';
import { ReactComponent as Lifebuoy } from '@portals/icons/linear/lifebuoy.svg';
import { ReactComponent as Logout } from '@portals/icons/linear/logout-1.svg';
import { ReactComponent as Notification } from '@portals/icons/linear/notification.svg';
import { ReactComponent as Snooze } from '@portals/icons/linear/snooze.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { RowMenuItem } from '@portals/table';

import { DeviceSnoozeMenu } from './device-card-menu/DeviceSnoozeMenu';
import { canEdit } from '../../../../../../lib/access';
import { DeleteDeviceModalProps, MoveDeviceProps } from '../../../../../modals';
import { ReportIncidentModalData } from '../../../../../modals/ReportIncident';

interface UseDeviceMenuItemsProps {
  device: DeviceType;
}

export const useDeviceMenuItems = ({ device }: UseDeviceMenuItemsProps) => {
  const openModal = useOpenModal();
  const withSupportCenter = useWithSupportCenter();

  const snoozeDevice = useSnoozeDevice(device.id);
  const unsnoozeDevice = useUnsnoozeDevice(device.id);

  const spaces = useSpaces();
  const currentSpace = find({ id: device.space_id }, spaces.data);
  const availableSpaces = filter(
    (space) => canEdit(space) && space !== currentSpace,
    spaces.data
  );

  const canMove = canEdit(currentSpace) && !isEmpty(availableSpaces);

  const onDeleteClick = async () => {
    openModal<DeleteDeviceModalProps['data']>('DeleteDeviceModal', {
      devices: [device],
    });
  };

  const menuItems: RowMenuItem[] = [
    {
      id: 'report-incident',
      label: 'Report Incident',
      Icon: Danger,
      onClick: () =>
        openModal<ReportIncidentModalData>('ReportIncident', { device }),
    },
    withSupportCenter
      ? {
          id: 'open-ticket',
          label: 'Open Ticket',
          Icon: Lifebuoy,
          onClick: () => openModal('CreateTicket', { device }),
        }
      : null,
    device.snoozed_until
      ? {
          id: 'turn-on-incident-tracking',
          label: 'Turn on incident tracking',
          Icon: Notification,
          onClick: () => unsnoozeDevice.mutate(),
          withBottomDivider: true,
        }
      : {
          id: 'snooze',
          label: 'Turn off incident tracking',
          Icon: Snooze,
          onClick: noop,
          Wrapper: ({ children }) => (
            <DeviceSnoozeMenu
              onSnoozeSelect={(minutes) => snoozeDevice.mutate(minutes)}
            >
              {children}
            </DeviceSnoozeMenu>
          ),
          withBottomDivider: true,
        },
    canMove
      ? {
          id: 'move-device',
          label: 'Move Device',
          Icon: Logout,
          onClick: () =>
            openModal<MoveDeviceProps['data']>('MoveDevice', { device }),
        }
      : null,
    {
      id: 'delete-device',
      label: 'Delete',
      color: 'red',
      Icon: Trash,
      onClick: onDeleteClick,
    },
  ];

  const filteredMenuItems: RowMenuItem[] = menuItems.filter(Boolean);

  return filteredMenuItems;
};
