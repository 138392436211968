import {
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React, { useState } from 'react';

import { DeviceType, useDeviceLicenses } from '@portals/api/organizations';
import { EmptyState, SearchInput } from '@portals/core';
import { useOpenModal } from '@portals/redux';
import { LicenseType } from '@portals/types';

import { AssignedLicenseCard } from './AssignedLicenseCard';
import { UnassignedLicensesModalData } from '../../../../modals';

interface LicensesProps {
  device: DeviceType;
}

export function Licenses({ device }: LicensesProps) {
  const { classes } = useStyles();
  const openModal = useOpenModal();

  const [searchTerm, setSearchTerm] = useState('');

  const licenses = useDeviceLicenses(device.id);

  const isEmptyAssignedLicenses = licenses.data?.length === 0;

  const filteredLicenses = getFilteredLicensesBySearchTerm(
    licenses.data || [],
    searchTerm
  );

  return (
    <Stack spacing="xl" className={classes.container}>
      <LoadingOverlay visible={licenses.isFetching} />

      <header>
        <Group position="apart">
          <SearchInput
            size="md"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClear={() => setSearchTerm('')}
            disabled={isEmptyAssignedLicenses}
          />

          <Button
            size="md"
            data-testid="assign-new-license-button"
            onClick={() =>
              openModal<UnassignedLicensesModalData>('UnassignedLicenses', {
                deviceModelId: device.partner.type_id,
                deviceId: device.id,
              })
            }
          >
            Assign new license
          </Button>
        </Group>
      </header>

      <Stack sx={{ flexGrow: 1 }}>
        {isEmptyAssignedLicenses ? (
          <EmptyState messageTitle="This device has no assigned licenses" />
        ) : (
          <>
            <Text className={classes.title}>Assigned Licenses</Text>
            <div className={classes.cardsGrid}>
              {filteredLicenses.map((license) => {
                return (
                  <AssignedLicenseCard
                    key={license.id}
                    license={license}
                    deviceId={device.id}
                  />
                );
              })}
            </div>
          </>
        )}
      </Stack>
    </Stack>
  );
}

function getFilteredLicensesBySearchTerm(
  licenses: LicenseType[],
  searchTerm: string
): LicenseType[] {
  if (!searchTerm) return licenses;

  const lowerSearchTerm = searchTerm.toLowerCase();

  return licenses.filter((license) => {
    return (
      license.product_name.toLowerCase().includes(lowerSearchTerm) ||
      license.product_subtitle?.toLowerCase()?.includes(lowerSearchTerm)
    );
  });
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%',
    paddingBlock: 30,
  },
  title: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 600,
    color: theme.colors.gray[6],
  },
  cardsGrid: {
    display: 'grid',
    gap: theme.spacing.xl,
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: 280,

    [theme.fn.largerThan(1600)]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
}));
