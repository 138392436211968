import { createStyles, Group, Text } from '@mantine/core';
import React from 'react';

import { PartnerSearchResultType } from '@portals/api/organizations';
import { PartnerAvatar } from '@portals/framework';

interface PartnerSearchResultProps {
  partner: PartnerSearchResultType;
  onPartnerSelected: (searchResult: PartnerSearchResultType) => void;
}

export function PartnerSearchResult({
  partner,
  onPartnerSelected,
}: PartnerSearchResultProps) {
  const { classes } = useStyles();

  return (
    <Group
      p="xs"
      onClick={() => onPartnerSelected(partner)}
      className={classes.container}
    >
      <PartnerAvatar
        src={partner.logo}
        partnerName={partner.display_name}
        size={46}
      />

      <Text weight={500}>{partner.display_name}</Text>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.md,

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
}));
