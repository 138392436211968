import { MantineTheme } from '@mantine/core';
import { useMemo } from 'react';

import { generateRelatedColors } from '@portals/utils';

import {
  PORTAL_CONFIG_BASE_URL,
  portalConfigQueryKeys,
} from './portal-config.constants';
import { PortalConfigType } from './portal-config.types';
import { useApiQuery } from '../../hooks';
import { QueryOptions } from '../../types';

export function usePortalConfig(
  queryOptions: QueryOptions<PortalConfigType | Record<string, never>> = {}
) {
  return useApiQuery<PortalConfigType | Record<string, never>>(
    PORTAL_CONFIG_BASE_URL,
    portalConfigQueryKeys.portalConfig,
    {
      // @ts-ignore
      placeholderData: {},
      ...queryOptions,
    }
  );
}

export function usePortalConfigByPartnerName(partnerName: string | undefined) {
  return useApiQuery<PortalConfigType>(
    `${PORTAL_CONFIG_BASE_URL}?partner_name=${partnerName}`,
    portalConfigQueryKeys.byPartnerName(partnerName),
    {
      enabled: !!partnerName,
    }
  );
}

export function useWidgetColors() {
  const portalConfig = usePortalConfig();

  return useMemo(() => {
    const result: Record<string, MantineTheme['colors']['string']> = {};
    const design = portalConfig.data?.design;

    if (design && design.custom_colors) {
      const customColors = design.custom_colors;

      for (const colorHex of customColors) {
        result[colorHex] = generateRelatedColors(colorHex);
      }
    }

    return result;
  }, [portalConfig.data?.design]);
}

// Helpers

export function useIsCecEnabled() {
  const portalConfig = usePortalConfig();

  return portalConfig.data?.cec_enabled;
}
