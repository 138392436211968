import { useDescope, useUser } from '@descope/react-sdk';
import {
  Box,
  createStyles,
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Switch,
  Text,
  Tooltip,
} from '@mantine/core';
import React, { useEffect } from 'react';

import { ReactComponent as Mobile } from '@portals/icons/linear/mobile.svg';
import { ReactComponent as SMS } from '@portals/icons/linear/sms.svg';
import { useOpenModal } from '@portals/redux';

import { AuthenticatorAppModalProps } from './AuthenticatorAppModal';
import { EmailMFAModalProps } from './EmailMFAModal';

export function Security() {
  const { classes, cx } = useStyles();

  const { user, isUserLoading } = useUser();
  const descopeSdk = useDescope();
  const openModal = useOpenModal();

  useEffect(() => {
    descopeSdk.refresh();
  }, [descopeSdk]);

  return (
    <>
      <LoadingOverlay visible={isUserLoading || !user} />

      <Paper withBorder radius="lg" p="lg">
        <Stack spacing="xl">
          <Stack>
            <Text c="gray.9">Two-factor-autentication (2FA)</Text>

            <Text c="gray.5">
              Keep your account secure by enabling 2FA via email or using a
              temporary one-time passcode (TOTP) from an authenticator app.
            </Text>
          </Stack>

          <Stack>
            <Paper
              withBorder
              radius="lg"
              p="xl"
              className={cx({
                [classes.disabled]: user?.customAttributes?.isTotpEnabled,
              })}
            >
              <Group position="apart">
                <Group spacing="xl">
                  <SMS />

                  <Stack spacing="xs">
                    <Text c="gray.9">Email</Text>

                    <Text fz="xs" c="gray.5">
                      Recieve a one-time passcode vie email each time you log
                      in.
                    </Text>
                  </Stack>
                </Group>

                {user?.customAttributes?.isTotpEnabled ? (
                  <Tooltip label="Only one 2FA method can be active at a time">
                    <Box>
                      <Switch
                        disabled={user?.customAttributes?.isTotpEnabled}
                      />
                    </Box>
                  </Tooltip>
                ) : (
                  <Switch
                    checked={user?.customAttributes?.isOtpEnabled}
                    onClick={(e) => {
                      e.preventDefault();

                      openModal<EmailMFAModalProps['data']>('EmailMFAModal', {
                        isOtpEnabled: user?.customAttributes?.isOtpEnabled,
                      });
                    }}
                    onChange={(e) => {
                      e.preventDefault();

                      openModal<EmailMFAModalProps['data']>('EmailMFAModal', {
                        isOtpEnabled: user?.customAttributes?.isOtpEnabled,
                      });
                    }}
                  />
                )}
              </Group>
            </Paper>

            <Paper
              withBorder
              radius="lg"
              p="xl"
              className={cx({
                [classes.disabled]: user?.customAttributes?.isOtpEnabled,
              })}
            >
              <Group position="apart">
                <Group spacing="xl">
                  <Mobile />

                  <Stack spacing="xs">
                    <Text c="gray.9">Authenticator app (TOTP)</Text>

                    <Text fz="xs" c="gray.5">
                      Use an app to recieve a temporary one-time passcode each
                      time you log in.
                    </Text>
                  </Stack>
                </Group>

                {user?.customAttributes?.isOtpEnabled ? (
                  <Tooltip label="Only one 2FA method can be active at a time">
                    <Box>
                      <Switch disabled={user?.customAttributes?.isOtpEnabled} />
                    </Box>
                  </Tooltip>
                ) : (
                  <Switch
                    checked={user?.customAttributes?.isTotpEnabled}
                    onChange={(e) => {
                      e.preventDefault();

                      openModal<AuthenticatorAppModalProps['data']>(
                        'AuthenticatorAppModal',
                        {
                          isTotpEnabled: user?.customAttributes?.isTotpEnabled,
                        }
                      );
                    }}
                  />
                )}
              </Group>
            </Paper>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  disabled: {
    opacity: 0.5,
  },
}));
