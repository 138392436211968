import { Box, createStyles, Paper, Popover, Stack, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import React, { useMemo, useRef, useState } from 'react';
import { useKey } from 'react-use';

import {
  DeviceModelAndPartnerSearchResultType,
  DeviceModelSearchResultType,
  PartnerSearchResultType,
  useSearchDeviceModelsAndPartners,
} from '@portals/api/organizations';
import { SearchInput } from '@portals/core';
import { RouteModalLink } from '@portals/framework/route-modals';

import { DeviceModelSearchResult } from './DeviceModelSearchResult';
import { PartnerSearchResult } from './PartnerSearchResult';

interface DeviceModelsAndPartnersSearchInputProps {
  onSearchResultSelected: (
    searchResult: DeviceModelAndPartnerSearchResultType
  ) => void;
}

export function DeviceModelsAndPartnersSearchInput({
  onSearchResultSelected,
}: DeviceModelsAndPartnersSearchInputProps) {
  const { classes, cx } = useStyles();

  const searchResultsElementRefs = useRef<Record<string, HTMLDivElement>>({});

  const [searchTerm, setSearchTerm] = useState('');

  const [hoveredSearchResultId, setHoveredSearchResultId] = useState('');

  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);

  const searchResults = useSearchDeviceModelsAndPartners(debouncedSearchTerm);

  const { deviceModels, partners } = useMemo(() => {
    const deviceModels: Array<DeviceModelSearchResultType> = [];
    const partners: Array<PartnerSearchResultType> = [];

    searchResults.data?.forEach((result) => {
      if (result.type === 'device_model') {
        deviceModels.push(result);
      } else if (result.type === 'partner') {
        partners.push(result);
      }
    });

    return { partners, deviceModels };
  }, [searchResults.data]);

  const scrollSearchResultElementIntoView = (searchResultId: string) => {
    const element = searchResultsElementRefs.current[searchResultId];

    if (element) {
      element.scrollIntoView({ block: 'nearest' });
    }
  };

  const onArrowDown = () => {
    // Clear the `hoveredSearchResultId` if the searchResult list is empty
    if (!searchResults.data || searchResults.data.length === 0) {
      setHoveredSearchResultId('');
      return;
    }

    let searchResultIdToHover: string;

    // If the `hoveredSearchResultId` is not set yet, set it to the first searchResult in the list
    if (!hoveredSearchResultId) {
      searchResultIdToHover = searchResults.data[0].id;

      setHoveredSearchResultId(searchResultIdToHover);
      scrollSearchResultElementIntoView(searchResultIdToHover);

      return;
    }

    const currentHoveredSearchResultIndex = searchResults.data.findIndex(
      (searchResult) => searchResult.id === hoveredSearchResultId
    );

    if (
      currentHoveredSearchResultIndex === -1 ||
      currentHoveredSearchResultIndex === searchResults.data.length - 1
    ) {
      // Set the `hoveredSearchResultId` to the first search result in the list
      searchResultIdToHover = searchResults.data[0].id;
    } else {
      // Set the `hoveredSearchResultId` to the next search result in `the list
      searchResultIdToHover =
        searchResults.data[currentHoveredSearchResultIndex + 1].id;
    }

    setHoveredSearchResultId(searchResultIdToHover);
    scrollSearchResultElementIntoView(searchResultIdToHover);
  };

  const onArrowUp = () => {
    // Clear the `hoveredSearchResultId` if the search results list is empty
    if (!searchResults.data || searchResults.data.length === 0) {
      setHoveredSearchResultId('');
      return;
    }

    let searchResultIdToHover: string;

    // If the `hoveredSearchResultId` is not set yet, set it to the last search result in the list
    if (!hoveredSearchResultId) {
      searchResultIdToHover =
        searchResults.data[searchResults.data.length - 1].id;

      setHoveredSearchResultId(searchResultIdToHover);
      scrollSearchResultElementIntoView(searchResultIdToHover);

      return;
    }

    const currentHoveredSearchResultIdToHoverIndex =
      searchResults.data.findIndex(
        (searchResult) => searchResult.id === hoveredSearchResultId
      );

    if (
      currentHoveredSearchResultIdToHoverIndex === -1 ||
      currentHoveredSearchResultIdToHoverIndex === 0
    ) {
      searchResultIdToHover =
        searchResults.data[searchResults.data.length - 1].id;
    } else {
      // Set the `hoveredSearchResultId` to the prev search result in the list
      searchResultIdToHover =
        searchResults.data[currentHoveredSearchResultIdToHoverIndex - 1].id;
    }

    setHoveredSearchResultId(searchResultIdToHover);
    scrollSearchResultElementIntoView(searchResultIdToHover);
  };

  const onEnter = () => {
    if (!hoveredSearchResultId) return;

    const selectedSearchResult = searchResults.data?.find(
      (searchResult) => searchResult.id === hoveredSearchResultId
    );

    if (!selectedSearchResult) return;

    onSearchResultSelected(selectedSearchResult);
  };

  useKey('ArrowDown', onArrowDown, {}, [searchResults, hoveredSearchResultId]);
  useKey('ArrowUp', onArrowUp, {}, [searchResults, hoveredSearchResultId]);
  useKey('Enter', onEnter, {}, [hoveredSearchResultId]);

  return (
    <Popover opened={searchTerm !== ''} width="target" shadow="lg">
      <Popover.Target>
        <SearchInput
          w="100%"
          maw={400}
          size="md"
          data-autofocus
          value={searchTerm}
          placeholder="Search brand or model name..."
          onChange={(event) => setSearchTerm(event.target.value)}
          onClear={() => setSearchTerm('')}
          loading={searchResults.isFetching}
        />
      </Popover.Target>

      <Popover.Dropdown p={0}>
        <Paper p="xs">
          {(deviceModels.length > 0 || partners.length > 0) && (
            <Box className={classes.results}>
              {deviceModels.length > 0 ? (
                <Stack spacing="xs">
                  <Text color="gray.5" weight={500} p="xs">
                    Models
                  </Text>

                  <Stack spacing="xs">
                    {deviceModels.map((deviceModel) => (
                      <Box
                        key={deviceModel.id}
                        ref={(el: HTMLDivElement) =>
                          (searchResultsElementRefs.current[deviceModel.id] =
                            el)
                        }
                        className={cx({
                          [classes.hoveredItem]:
                            hoveredSearchResultId === deviceModel.id,
                        })}
                      >
                        <DeviceModelSearchResult
                          key={deviceModel.id}
                          deviceModel={deviceModel}
                          onDeviceModelSelected={onSearchResultSelected}
                        />
                      </Box>
                    ))}
                  </Stack>
                </Stack>
              ) : null}

              {partners.length > 0 ? (
                <Stack spacing="xs">
                  <Text color="gray.5" weight={500} p="xs">
                    Brands
                  </Text>

                  <Stack spacing="xs">
                    {partners.map((partner) => (
                      <Box
                        key={partner.id}
                        ref={(el: HTMLDivElement) =>
                          (searchResultsElementRefs.current[partner.id] = el)
                        }
                        className={cx({
                          [classes.hoveredItem]:
                            hoveredSearchResultId === partner.id,
                        })}
                      >
                        <PartnerSearchResult
                          partner={partner}
                          onPartnerSelected={onSearchResultSelected}
                        />
                      </Box>
                    ))}
                  </Stack>
                </Stack>
              ) : null}
            </Box>
          )}

          <Stack spacing={4} align="center" p="sm">
            <Text size="xs" weight={600}>
              Can't find your brand?
            </Text>
            <Text size="xs">Connect them now to add your devices!</Text>

            <RouteModalLink modalId="connect" size="xs" weight={600}>
              Connect
            </RouteModalLink>
          </Stack>
        </Paper>
      </Popover.Dropdown>
    </Popover>
  );
}

const useStyles = createStyles((theme) => ({
  results: {
    overflow: 'auto',
    maxHeight: 350,
    paddingBottom: theme.spacing.xs,
    borderBottom: `1px solid ${theme.colors.gray[2]} `,
  },
  hoveredItem: {
    borderColor: theme.colors.gray[5],
    backgroundColor: theme.colors.gray[2],
  },
}));
