import {
  Badge,
  BadgeProps,
  Button,
  Center,
  createStyles,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useFeatureFlags,
  useOrganizationConfig,
  useSendSupportEmail,
} from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';
import { ModalCenteredMediaLayout } from '@portals/core';
import { C2C_INTEGRATIONS, C2CIntegrationItem } from '@portals/framework';
import { useIntegrations } from '@portals/redux';

import { CecConnectionCard } from './CecConnectionCard';

interface OrgC2CIntegrationItem extends C2CIntegrationItem {
  href?: string;
  isComingSoon?: boolean;
}

function getIntegrationsList(
  featureFlags: ReturnType<typeof useFeatureFlags>
): Array<OrgC2CIntegrationItem> {
  return [
    {
      ...C2C_INTEGRATIONS.mtr,
      href: '/settings/integrations/uc/mtr',
    },
    {
      ...C2C_INTEGRATIONS.zoom,
      href: '/settings/integrations/uc/zoom',
    },
    {
      ...C2C_INTEGRATIONS.xio,
      href: '/settings/integrations/monitoring/xio',
    },
    featureFlags?.qsc_integration
      ? {
          ...C2C_INTEGRATIONS.q_sys,
          href: '/settings/integrations/monitoring/q_sys',
        }
      : {
          ...C2C_INTEGRATIONS.q_sys,
          isComingSoon: true,
        },
    {
      ...C2C_INTEGRATIONS.logitech,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.webex,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.poly,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.neat,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.qsc,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.novastar,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.yale,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS['2n'],
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.honeywell,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.lenel,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.assa,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.intune,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.biamp,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.yealink,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.barco,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.visionline,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.avigilon,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.brivo,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.controlbyweb,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS['4suites'],
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.pti,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS.salto,
      isComingSoon: true,
    },
    {
      ...C2C_INTEGRATIONS['2n_intercom'],
      isComingSoon: true,
    },
  ];
}

interface C2CIntegrationsProps {
  searchTerm: string;
}

export function C2CIntegrations({ searchTerm }: C2CIntegrationsProps) {
  const organizationConfig = useOrganizationConfig();
  const currentUser = useCurrentUser();
  const featureFlags = useFeatureFlags();

  const { classes } = useStyles();

  const navigate = useNavigate();

  const integrations = useIntegrations();
  const sendSupportEmail = useSendSupportEmail();

  const [selectedComingSoonIntegration, setSelectedComingSoonIntegration] =
    useState<OrgC2CIntegrationItem | null>(null);

  const onConnectClick = (integration: OrgC2CIntegrationItem) => {
    if (integration.isComingSoon) {
      sendSupportEmail.mutate(
        {
          title: `${organizationConfig.name} is asking to integrate with ${integration.name}`,
          message: `Dear Support team member,

${organizationConfig.name} has asked to integrate with ${integration.name}.
Please get back to the requesting user: ${currentUser.data?.name} at ${currentUser.data?.email} to understand their need.

The Xyte team`,
        },
        {
          onSuccess: () => {
            setSelectedComingSoonIntegration(integration);
          },
        }
      );
    } else if (integration.href) {
      navigate(integration.href);
    }
  };

  const integrationsList = useMemo(
    () => getIntegrationsList(featureFlags),
    [featureFlags]
  );

  const filteredIntegrations = useMemo(() => {
    if (searchTerm === '') return integrationsList;

    const lowerSearchTerm = searchTerm.toLowerCase();

    return integrationsList.filter((integration) => {
      const searchableContent =
        `${integration.name}__@@__${integration.description}`.toLowerCase();

      return searchableContent.includes(lowerSearchTerm);
    });
  }, [integrationsList, searchTerm]);

  if (filteredIntegrations.length === 0) {
    return null;
  }

  return (
    <>
      <LoadingOverlay visible={sendSupportEmail.isLoading} />

      <Stack w="100%">
        <Text c="gray.6" fz="lg">
          Cloud to cloud integrations
        </Text>

        <div className={classes.grid}>
          {filteredIntegrations.map((integration) => (
            <CecConnectionCard
              key={integration.id}
              id={integration.id}
              logo={integration.logo}
              title={integration.name}
              description={integration.description}
              isComingSoon={integration.isComingSoon}
              isConnected={integrations?.[integration.id]?.state === 'active'}
              searchTerm={searchTerm}
              action={
                <Button
                  onClick={() => onConnectClick(integration)}
                  data-testid={
                    integration.isComingSoon
                      ? 'c2c-card-ask-to-connect-btn'
                      : 'c2c-card-connect-btn'
                  }
                >
                  {integration.isComingSoon ? 'Ask to connect' : 'Connect'}
                </Button>
              }
            />
          ))}
        </div>
      </Stack>

      {selectedComingSoonIntegration && (
        <ModalCenteredMediaLayout
          opened
          onClose={() => setSelectedComingSoonIntegration(null)}
          title={
            <Stack align="center">
              <Badge size="lg" styles={badgeStyles}>
                Coming soon
              </Badge>
              <Text>{selectedComingSoonIntegration.name} is coming soon</Text>
            </Stack>
          }
          classNames={{
            header: classes.header,
            headerContent: classes.headerContent,
          }}
          mediaSize={80}
          media={
            <img
              src={selectedComingSoonIntegration.logo}
              alt={selectedComingSoonIntegration.name}
            />
          }
          footer={
            <Center>
              <Button
                size="md"
                w={160}
                onClick={() => setSelectedComingSoonIntegration(null)}
              >
                Got it
              </Button>
            </Center>
          }
        >
          <Text ta="center">
            Our Customer Support team will inform you once this integration is
            available. Stay tuned!
          </Text>
        </ModalCenteredMediaLayout>
      )}
    </>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
  },
  headerContent: {
    gap: theme.spacing.xs,
  },
  grid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(290px, 1fr))',
    gridAutoRows: 'minmax(290px, auto)',
    gap: theme.spacing.xl,
    placeContent: 'start',
  },
}));

const badgeStyles: BadgeProps['styles'] = (theme) => ({
  root: {
    textTransform: 'none',
    backgroundColor: theme.fn.rgba(theme.colors.indigo[0], 0.6),
  },
  inner: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 400,
    color: theme.colors.gray[9],
  },
});
