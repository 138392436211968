import {
  Center,
  Divider,
  Group,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import React from 'react';

export function FeedbackButton() {
  const theme = useMantineTheme();

  return (
    <Group align="center">
      <UnstyledButton data-analyticsid="pendo-feedback-button">
        <Text color="blue_gray.6" size="sm">
          Submit Feedback
        </Text>
      </UnstyledButton>

      <Center h={42}>
        <Divider
          orientation="vertical"
          color={theme.fn.rgba(theme.colors.blue_gray[6], 0.5)}
        />
      </Center>
    </Group>
  );
}
