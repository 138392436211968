import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';

import { SUPPORT_API_URL } from './support.constants';
import { ServerError } from '../../types';
import { fetchApiRequest, useRequestOptions } from '../../utils';

interface UseSendSupportEmailParams {
  title: string;
  message: string;
}

export function useSendSupportEmail() {
  const dispatch = useDispatch();

  const { url, options } = useRequestOptions({
    url: `${SUPPORT_API_URL}/contact`,
    method: 'POST',
  });

  return useMutation<void, ServerError, UseSendSupportEmailParams>({
    mutationFn: ({ title, message }) => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({ title, message }),
      });
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
  });
}
