import { UserPermissions } from '@portals/types';

interface AccessibleTenant {
  id: string;
  name: string;
  display_name: string;
}

interface UserSettingsType {
  is_welcome_videos_played: boolean;
  profile_image: string;
  is_mfa_notification_dismissed: boolean;
}

export interface UserResponseType {
  id: string;
  name: string;
  organization_display_name: string | null;
  partner_display_name: string | null;
  partner_id: string | null;
  email: string;
  sign_in_count: number;
  last_sign_in_at: string | null;
  last_seen_at: string | null;
  last_ip: string | null;
  created_at: string;
  group_names: string[];
  has_support_seat: boolean;
  deactivated_at: string | null;
  settings: UserSettingsType;
}

export enum FeatureNotificationEnum {
  NewOrganizationDashboard = 'new_organization_dashboard',
  NewSettingsDesign = 'new_settings_design',
  NewPartnerToPartner = 'new_partner_to_partner',
  NewPartnerHubs = 'new_partner_hubs',
}

export interface SelfUserResponseType extends UserResponseType {
  super_admin: boolean;
  local_admin: boolean;
  access: string[];
  permissions: UserPermissions;
  is_external: boolean;
  feature_notifications: FeatureNotificationEnum[];
  accessible_tenants: {
    organizations: Array<AccessibleTenant>;
    partners: Array<AccessibleTenant>;
  };
}

export interface WelcomeDetailsType {
  name: string;
  email: string;
  tenant_name: string;
}

export interface OrganizationWithUserAccess {
  logo_url: string;
  organization_display_name: string;
  organization_id: string;
}
