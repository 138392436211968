import { Box, Button, createStyles, Title } from '@mantine/core';
import { find } from 'lodash/fp';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import {
  SpaceType,
  useClaimDeviceByQrCode,
  useSpaces,
} from '@portals/api/organizations';
import { useOpenModal } from '@portals/redux';

import { canView, noAccess } from '../../../../lib/access';
import { SpaceTree } from '../../../components/common/SpaceTree/SpaceTree';
import { PageContainer } from '../../../components/layout/Page';

export function Overview() {
  const { classes } = useStyles();
  const spaces = useSpaces();
  const navigate = useNavigate();
  const location = useLocation();
  const openModal = useOpenModal();
  const claimDevice = useClaimDeviceByQrCode();

  const [selectedSpace, setSelectedSpace] = useState<SpaceType>(null);

  const onClaimDeviceByQRCode = async (qrCode: string) => {
    if (!qrCode) return;

    claimDevice.mutate(
      { qr: qrCode },
      {
        onSuccess: ({ device }) => {
          navigate(`/devices/${device.id}?auto-claimed=true`, {
            state: true,
          });
        },
        onError: () => {
          navigate('/', { state: true });
        },
      }
    );
  };

  const { cloudId } = useParams<{ cloudId: string }>();

  // When a user scans QR code outside our app, and gets straight to the claiming page
  useEffectOnce(function autoClaimDevice() {
    const qrCode = new URLSearchParams(location.search).get('qr') || cloudId;

    if (!qrCode) return;

    onClaimDeviceByQRCode(qrCode);
  });

  const onSelect = (spaceId: SpaceType['id']) => {
    if (spaceId === selectedSpace?.id) {
      setSelectedSpace(null);

      return;
    }

    const space = find({ id: spaceId }, spaces.data);

    if (space && canView(space)) setSelectedSpace(space);
  };

  const onNext = () => {
    if (!selectedSpace?.id || noAccess(selectedSpace)) return;

    navigate(`/overview/${selectedSpace.id}`);
  };

  return (
    <PageContainer>
      <Box py="lg" px="md" className={classes.content}>
        <Title order={4} data-testid="spaces-title">
          Spaces
        </Title>

        <Box className={classes.tree}>
          <SpaceTree
            spaces={spaces.data}
            draggable={false}
            openModal={openModal}
            handleSelected={onSelect}
            selected={selectedSpace}
          />
        </Box>

        <Button
          disabled={!selectedSpace}
          size="lg"
          onClick={onNext}
          data-testid="continue-button"
        >
          {!selectedSpace ? 'Select a space to continue' : 'Continue'}
        </Button>
      </Box>
    </PageContainer>
  );
}

const useStyles = createStyles((theme) => ({
  content: {
    display: 'grid',
    gridTemplateRows: 'min-content 1fr min-content',
    gridRowGap: theme.spacing.md,
  },

  tree: {
    border: `1px solid ${theme.colors.gray[3]}`,

    '.tree-container': {
      gridTemplateRows: '0 70px 1fr',

      '.tree-header': {
        gridTemplateColumns: '1fr',

        '.tree-actions': {
          display: 'none',
        },

        input: {
          minHeight: 45,
        },
      },

      '.rc-tree-list': {
        '.rst_toggleButtonIcon': {
          width: 20,
          height: 20,
        },

        '.rc-tree-treenode': {
          height: '40px !important',

          '.inline-space-name-input': {
            fontSize: `${theme.fontSizes.md} !important`,
          },

          '.rc-tree-iconEle': {
            width: 20,
            height: 20,

            svg: {
              width: '20px !important',
              height: '20px !important',
            },
          },

          '.rc-tree-title': {
            paddingLeft: theme.spacing.md,
            fontSize: theme.fontSizes.md,
          },
        },
      },
    },
  },

  action: {
    backgroundColor: theme.colors.gray[2],
  },
}));
