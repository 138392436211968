import {
  BackgroundImage,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Modal,
  ModalProps as MantineModalProps,
  Stack,
  Text,
} from '@mantine/core';
import React, { useMemo } from 'react';

import {
  DeviceModelAndPartnerSearchResultType,
  SpaceType,
  useCecPartners,
  useDeviceModels,
} from '@portals/api/organizations';
import { ModalProps, usePermissionAccess } from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { useOpenModal } from '@portals/redux';
import { CecPartnerType } from '@portals/types';

import { CecPartnersEmptyState } from './CecPartnersEmptyState';
import { DeviceModelsAndPartnersSearchInput } from './DeviceModelsAndPartnersSearchInput';
import { PartnerCard } from './PartnerCard';
import findPartnerBackground from '../../../../assets/img/find-partner-background.svg';
import { AddDeviceModalProps } from '../AddDeviceModal';
import { SelectModelFromPartnerModalProps } from '../SelectModelFromPartnerModal';

export interface SelectPartnerOrDeviceModelModalProps
  extends ModalProps<{ spaceId?: SpaceType['id'] }> {}

export function SelectPartnerOrDeviceModelModal({
  closeMe,
  data: { spaceId },
}: SelectPartnerOrDeviceModelModalProps) {
  const { classes } = useStyles();

  const cecPartners = useCecPartners();
  const openModal = useOpenModal();
  const openRouteModal = useOpenRouteModal();
  const { isAdmin } = usePermissionAccess();

  const deviceModels = useDeviceModels();

  const partnersToDisplay = useMemo(
    () => (cecPartners.data || []).filter((partner) => partner.connected),
    [cecPartners.data]
  );

  const onSearchResultSelected = (
    searchResult: DeviceModelAndPartnerSearchResultType
  ) => {
    closeMe();

    if (searchResult.type === 'device_model') {
      const selectedModel = deviceModels.data?.find(
        (model) => model.id === searchResult.id
      );

      openModal<AddDeviceModalProps['data']>('AddDeviceModal', {
        spaceId,
        selectedModel,
      });
    } else {
      openModal<SelectModelFromPartnerModalProps['data']>(
        'SelectModelFromPartnerModal',
        {
          spaceId,
          partnerId: searchResult.id,
        }
      );
    }
  };

  const onConnectClick = () => {
    closeMe();
    openRouteModal({ modalId: 'connect' });
  };

  const onPartnerSelected = (partner: CecPartnerType) => {
    closeMe();
    openModal<SelectModelFromPartnerModalProps['data']>(
      'SelectModelFromPartnerModal',
      {
        spaceId,
        partnerId: partner.id,
      }
    );
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      title="Add New Device"
      size="100%"
      padding={0}
      styles={modalStyles}
    >
      <div className={classes.bodyContent}>
        <LoadingOverlay visible={cecPartners.isInitialLoading} />

        {partnersToDisplay.length === 0 ? (
          <CecPartnersEmptyState handelConnectClick={onConnectClick} />
        ) : (
          <Stack spacing="xl" h="100%">
            <Stack align="center" h="100%">
              <Text size="md" color="gray.6" data-testid="brand-selection">
                Select the brand from which you would like to add the device
              </Text>

              <DeviceModelsAndPartnersSearchInput
                onSearchResultSelected={onSearchResultSelected}
              />

              <Text color="gray.5">Brands</Text>

              <div className={classes.grid}>
                {partnersToDisplay.map((partner) => (
                  <PartnerCard
                    key={partner.id}
                    partner={partner}
                    handlePartnerSelected={onPartnerSelected}
                  />
                ))}
              </div>
            </Stack>

            {isAdmin ? (
              <BackgroundImage src={findPartnerBackground} radius="lg">
                <Group w="100%" h="100%" position="apart" p="xxl">
                  <Stack spacing="xs">
                    <Text size="lg" weight={500}>
                      Can't find your brand?
                    </Text>
                    <Text>
                      Connect them now using Connect+ to seamlessly integrate
                      your devices!
                    </Text>
                  </Stack>

                  <Button
                    variant="default"
                    onClick={onConnectClick}
                    data-testid="go-to-connect-button"
                  >
                    Go to connect
                  </Button>
                </Group>
              </BackgroundImage>
            ) : null}
          </Stack>
        )}
      </div>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  content: {
    minWidth: '100%',
    minHeight: '100%',
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    backgroundColor: theme.colors.gray[0],
  },
  header: {
    padding: theme.spacing.xl,
  },
});

const useStyles = createStyles((theme) => ({
  bodyContent: {
    position: 'relative',
    height: '100%',
    maxWidth: 1280,
    marginInline: 'auto',
    padding: theme.spacing.xxl,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(290px, 1fr))',
    gridAutoRows: 'minmax(290px, auto)',
    gap: theme.spacing.md,
    placeContent: 'start',
    alignSelf: 'flex-start',
    width: '100%',

    [theme.fn.smallerThan('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));
