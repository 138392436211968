import {
  Anchor,
  Box,
  Button,
  SegmentedControl,
  Stack,
  Text,
} from '@mantine/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  DeviceType,
  TICKETS_API_URL,
  TicketSummaryType,
  useFeatureFlags,
  useTickets,
} from '@portals/api/organizations';
import { messageBubblesSrc, TicketStatusBadge } from '@portals/framework';
import { useOpenModal } from '@portals/redux';
import {
  AvatarCell,
  BooleanCell,
  DateCell,
  PaginatedTable,
} from '@portals/table';
import { TableColumn, TableFilterTypeEnum } from '@portals/types';

import { TicketsTableDetailsPanel } from '../../../../components/tickets/ticket-details-panel/TicketsTableDetailsPanel';
import { CreateTicketProps } from '../../../../modals';

interface TicketsProps {
  device: DeviceType;
}

export function Tickets({ device }: TicketsProps) {
  const openModal = useOpenModal();

  const featureFlags = useFeatureFlags();

  const [showActiveTicketsOnly, setShowActiveTicketsOnly] = useState(true);

  const dataHookUrl = showActiveTicketsOnly
    ? `${TICKETS_API_URL}/?q[device_id_eq]=${device.id}&q[status_not_eq]=resolved`
    : `${TICKETS_API_URL}/?q[device_id_eq]=${device.id}`;

  const columns: Array<TableColumn<TicketSummaryType>> = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      isSticky: true,
      minWidth: 300,
      formatter: (_, { id, title }) => {
        if (!featureFlags?.hide_new_tickets_ui) {
          return <Text>{title}</Text>;
        }

        return (
          <Anchor component={Link} to={`/tickets/${id}`}>
            {title}
          </Anchor>
        );
      },
    },
    {
      dataField: 'description',
      text: 'Description',
    },
    {
      dataField: 'partner_display_name',
      text: 'Manufacturer',
      sort: true,
      filter: {
        type: TableFilterTypeEnum.Text,
      },
      formatter: (_, { partner_display_name, partner_logo_url }) => (
        <AvatarCell label={partner_display_name} src={partner_logo_url} />
      ),
    },
    {
      dataField: 'org_seen',
      text: 'Seen',
      sort: true,
      minWidth: 180,
      maxWidth: 180,
      filter: {
        type: TableFilterTypeEnum.SingleSelect,
        options: { true: 'Seen', false: 'Not seen', all: 'All' },
        placeholder: 'Seen...',
      },
      formatter: (cell, { org_seen }) => <BooleanCell isActive={org_seen} />,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      minWidth: 180,
      maxWidth: 180,
      formatter: (_, { status }) => <TicketStatusBadge status={status} />,
      filter: {
        type: TableFilterTypeEnum.Select,
        options: showActiveTicketsOnly
          ? {
              open: 'Open',
              replied: 'Replied',
              review: 'Review',
            }
          : {
              open: 'Open',
              replied: 'Replied',
              review: 'Review',
              resolved: 'Resolved',
            },
      },
    },
    {
      dataField: 'created_at',
      text: 'Created',
      sort: true,
      filter: { type: TableFilterTypeEnum.Date },
      formatter: (_, { created_at }) => <DateCell date={created_at} />,
    },
  ];

  const onOpenTicket = () => {
    openModal<CreateTicketProps['data']>('CreateTicket', { device });
  };

  return (
    <Stack spacing="xl" h="100%" py="xl">
      <SegmentedControl
        fullWidth
        data={[
          { label: 'Active tickets', value: 'active' },
          { label: 'All Tickets', value: 'all' },
        ]}
        value={showActiveTicketsOnly ? 'active' : 'all'}
        onChange={(value) => setShowActiveTicketsOnly(value === 'active')}
      />

      <PaginatedTable<TicketSummaryType>
        keyField="id"
        name="organizations.device.tickets"
        additionalActions={() => (
          <Button onClick={onOpenTicket}>Open Ticket</Button>
        )}
        noColumnsSelection
        isUrlSyncEnabled={false}
        dataHook={useTickets}
        dataHookUrl={dataHookUrl}
        columns={columns}
        detailsPanel={{
          type: 'inner',
          renderer: ({ row, onClose, tableRef }) => (
            <TicketsTableDetailsPanel
              ticketId={row.original.id}
              showOpenDeviceButton={false}
              detailsPanelType="inner"
              onClose={onClose}
              tableRef={tableRef}
            />
          ),
        }}
        noDataIndication={{
          title: 'No active tickets',
          assetSrc: messageBubblesSrc,
          subtitle: (
            <Box>
              <Text>
                You currently have no support tickets for this device.
              </Text>

              <Text> Create a new ticket to get help. </Text>
            </Box>
          ),
        }}
      />
    </Stack>
  );
}
