import { Descope } from '@descope/react-sdk';
import { DescopeProps } from '@descope/react-sdk/dist/dts/src/types';
import {
  Center,
  Loader,
  Modal,
  ModalProps as MantineModalProps,
} from '@mantine/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { toastrError, toastrSuccess } from '@portals/redux/actions/toastr';

import { ModalProps } from '../../components/Modals';

export interface EmailMFAModalProps
  extends ModalProps<{
    isOtpEnabled: boolean;
    setIsOtpEnabled?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  }> {}

export function EmailMFAModal({ data, closeMe }: EmailMFAModalProps) {
  const { isOtpEnabled, setIsOtpEnabled } = data;
  const dispatch = useDispatch();

  const [isDescopeFlowLoading, setIsDescopeFlowLoading] = useState(true);

  const onSuccess = (e: Parameters<DescopeProps['onSuccess']>[0]) => {
    if (!e.detail.user.customAttributes?.isOtpEnabled && isOtpEnabled) {
      dispatch(
        toastrSuccess(
          'Two-Factor Authentication via email has been successfully disabled'
        )
      );

      setIsOtpEnabled?.(false);

      closeMe();
      return;
    }

    if (e.detail.user.customAttributes?.isOtpEnabled && !isOtpEnabled) {
      dispatch(
        toastrSuccess(
          'Two-Factor Authentication via email has been successfully set up'
        )
      );

      setIsOtpEnabled?.(true);

      closeMe();
      return;
    }

    dispatch(toastrError('Something went wrong'));

    closeMe();
  };

  return (
    <Modal
      opened
      onClose={closeMe}
      radius="lg"
      padding="xxl"
      styles={modalStyles}
    >
      {isDescopeFlowLoading && (
        <Center mx="auto" h={400}>
          <Loader />
        </Center>
      )}

      <Descope
        flowId={isOtpEnabled ? 'cancel-mfa-email' : 'mfa-email'}
        onSuccess={onSuccess}
        onReady={() => setIsDescopeFlowLoading(false)}
      />
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  body: {
    minHeight: 480,
  },
});
