import { createStyles, Text } from '@mantine/core';
import React from 'react';
import {
  generatePath,
  matchPath,
  NavLink,
  useLocation,
} from 'react-router-dom';

import { useTranslation } from '@portals/i18n';
import {
  CommonConfigurationType,
  ComponentRendererType,
  RouteType,
} from '@portals/types';

export type SidebarItemProps = Pick<
  RouteType<CommonConfigurationType>,
  | 'id'
  | 'hasBorder'
  | 'badgeColor'
  | 'badgeText'
  | 'badgeComponent'
  | 'indicatorComponent'
  | 'icon'
> & {
  to: string[];
};

export interface LinkBodyProps {
  id?: string;
  href?: string;
  allPaths?: string | Array<string>;
  icon?: ComponentRendererType;
  badgeComponent?: ComponentRendererType;
  indicatorComponent?: ComponentRendererType;
  badgeColor?: string;
  badgeText?: string;
  label?: string;
}

export function LinkBody({
  icon: Icon,
  indicatorComponent: Indicator,
}: LinkBodyProps) {
  if (Indicator && Icon) {
    return (
      <Indicator>
        <Icon size={24} />
      </Indicator>
    );
  }

  return Icon ? <Icon size={24} /> : null;
}

export function SidebarItem({
  id,
  badgeComponent,
  indicatorComponent,
  badgeColor,
  badgeText,
  icon,
  to,
}: SidebarItemProps) {
  const location = useLocation();

  const isMatch = to.some((path) =>
    matchPath({ path, end: true }, location.pathname)
  );

  const { classes, cx } = useStyles();

  const t = useTranslation();
  const label = t(id || '');

  return (
    <div className={classes.container}>
      <NavLink
        to={generatePath(to[0])}
        className={cx('sidebar-link', { active: isMatch })}
        data-testid={`sidebar-item-${id}`}
      >
        <LinkBody
          icon={icon}
          label={label}
          badgeColor={badgeColor}
          badgeText={badgeText}
          badgeComponent={badgeComponent}
          indicatorComponent={indicatorComponent}
        />

        <Text ta="center" fz={10}>
          {label}
        </Text>
      </NavLink>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    '.sidebar-link': {
      display: 'grid',
      gridTemplateRows: 'min-content auto',
      padding: 0,
      justifyItems: 'center',
      alignItems: 'center',
      width: '100%',
      borderRadius: theme.radius.md,
      textDecoration: 'none',
      cursor: 'pointer',
      color: theme.colors.gray[7],
      gap: theme.spacing.xs,
      paddingBlock: theme.spacing.xs,

      'i, svg, path': {
        stroke: theme.colors.gray[9],
      },

      '&.active': {
        fontWeight: 600,

        backgroundColor: theme.colors.gray[2],
        color: theme.colors.gray[9],
      },

      '&:not(.active)': {
        ':hover': {
          backgroundColor: theme.colors.gray[1],
        },
      },
    },
  },
}));
