import { createStyles, Group, Paper, Stack, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { DeviceDetailsType, useSpace } from '@portals/api/organizations';
import { DeviceAvatarWithIndicator } from '@portals/framework';

interface ChildDevicesProps {
  device: DeviceDetailsType;
}

export function DeviceParent({ device }: ChildDevicesProps) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const space = useSpace({ spaceId: device?.parent_device?.space_id });

  if (!device?.parent_device) return null;

  return (
    <Paper p="xl" radius="xl">
      <Stack>
        <Text color="gray.9" size="sm">
          Parent device
        </Text>

        <Group
          key={device?.parent_device.id}
          noWrap
          className={classes.childDeviceContainer}
          data-testid="child-device-container"
          onClick={() => navigate(`/devices/${device?.parent_device.id}`)}
          px="xs"
        >
          <DeviceAvatarWithIndicator
            deviceName={device?.parent_device.name}
            deviceStatus={device?.parent_device.state?.status}
            deviceModelSettings={device?.parent_device.model_settings}
            avatarProps={{ size: 'lg' }}
          />

          <div className={classes.textsContainer}>
            <Text
              truncate
              color="blue_gray.9"
              weight={600}
              data-testid="child-device-name-display"
            >
              {device?.parent_device.name}
            </Text>
            <Text truncate size="xs" color="gray.6" weight={600}>
              {device?.parent_device.device_model_name}
            </Text>
            <Text truncate size="xs" color="gray.5" mt="xs">
              {space.name}
            </Text>
          </div>
        </Group>
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  childDeviceContainer: {
    padding: `${theme.spacing.md} 0`,
    borderRadius: theme.radius.md,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  textsContainer: {
    overflow: 'hidden',
  },
}));
