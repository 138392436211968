import {
  Button,
  Paper,
  Stack,
  Text,
  TextInput,
  LoadingOverlay,
  createStyles,
  ButtonProps,
  Overlay,
  Box,
  Group,
} from '@mantine/core';
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { useHover } from '@mantine/hooks';
import React, { useRef } from 'react';

import { useUploadFileToS3 } from '@portals/api';
import {
  useCurrentUser,
  UserResponseType,
  useUpdateUser,
} from '@portals/api/ui';
import { NameAbbreviationAvatar } from '@portals/core';
import { ReactComponent as Edit } from '@portals/icons/linear/edit-3.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';

interface MyAccountProps {
  user: UserResponseType;
}

export function MyAccountWrapper() {
  const currentUser = useCurrentUser();

  if (currentUser.isInitialLoading || !currentUser.data) {
    return <LoadingOverlay visible />;
  }

  return <MyAccount user={currentUser.data} />;
}
export function MyAccount({ user }: MyAccountProps) {
  const { classes } = useStyles();

  const openRef = useRef<() => void>(null);

  const updateUser = useUpdateUser();
  const uploadFileToS3 = useUploadFileToS3();

  const form = useForm({
    initialValues: {
      fullName: user.name,
      email: user.email,
      profileImage: user.settings.profile_image,
    },
  });

  const { hovered, ref } = useHover();

  const openModal = useOpenModal();

  const onDrop = async (files: FileWithPath[]) => {
    try {
      const fileBlob = await fileToBlob(files[0]);
      const fileUrl = await uploadFileToS3.mutateAsync({
        originalFileName: files[0].name,
        blob: fileBlob,
      });

      updateUser.mutate(
        {
          user: {
            ...user,
            id: user.id,
            name: form.values.fullName,
            settings: {
              ...user.settings,
              profile_image: fileUrl,
            },
          },
        },
        {
          onSuccess: () => {
            form.setFieldValue('img', fileUrl);
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onRemoveAvatar = () => {
    updateUser.mutate(
      {
        user: {
          ...user,
          id: user.id,
          name: form.values.fullName,
          settings: {
            ...user.settings,
            profile_image: '',
          },
        },
      },
      {
        onSuccess: () => {
          form.setFieldValue('img', '');
        },
      }
    );
  };

  const onChangeName = () => {
    if (form.values.fullName === user.name) {
      return;
    }

    updateUser.mutate({
      user: {
        ...user,
        id: user.id,
        name: form.values.fullName,
        settings: {
          ...user.settings,
          profile_image: form.values.profileImage,
        },
      },
    });
  };

  return (
    <Stack>
      <Stack ta="center" w="fit-content" className={classes.container}>
        <Box ref={ref} className={classes.dropzoneContainer}>
          <Dropzone
            onDrop={onDrop}
            accept={IMAGE_MIME_TYPE}
            className={classes.dropzone}
            styles={{ inner: { pointerEvents: 'all' } }}
            activateOnClick={false}
            openRef={openRef}
          >
            {hovered ? (
              <Overlay center zIndex={1000} radius={98} h={98} w={98} m="md">
                <Group spacing="xl">
                  <Edit color="white" onClick={() => openRef.current?.()} />

                  {form.values.profileImage && (
                    <Trash color="white" onClick={onRemoveAvatar} />
                  )}
                </Group>
              </Overlay>
            ) : null}

            <NameAbbreviationAvatar
              name={user.name}
              radius={98}
              size={98}
              withToolTip={false}
              src={user.settings.profile_image}
            />
          </Dropzone>
        </Box>

        <Stack spacing="xs">
          <Text c="gray.9" fz="lg" data-testid="current-user-name">
            {user.name}
          </Text>

          <Text c="gray.5">{user.email}</Text>
        </Stack>
      </Stack>

      <Paper p="xxl" radius="lg" withBorder>
        <form>
          <Stack align="flex-start" spacing="xl">
            <Text fz="md" c="gray.8">
              Profile
            </Text>

            <TextInput
              label="Full name"
              placeholder="Full name"
              {...form.getInputProps('fullName')}
              onBlur={onChangeName}
              data-testid="full-name-text-input"
            />

            <Stack spacing="xxl">
              <Text>Password</Text>

              <Button
                variant="light"
                color="gray"
                onClick={() => openModal('ChangePasswordModal')}
                styles={buttonStyles}
                data-testid="change-password-button"
              >
                Change password
              </Button>
            </Stack>
          </Stack>
        </form>
      </Paper>
    </Stack>
  );
}

async function fileToBlob(file: File) {
  return new Blob([new Uint8Array(await file.arrayBuffer())], {
    type: file.type,
  });
}

const useStyles = createStyles((theme) => ({
  dropzone: {
    border: 0,
    backgroundColor: 'transparent',
    alignSelf: 'center',
  },

  dropzoneContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    alignSelf: 'center',
  },

  container: {
    alignSelf: 'center',
  },
}));

const buttonStyles: ButtonProps['styles'] = (theme) => ({
  root: {
    color: theme.colors.gray[9],
  },
});
