import React from 'react';

import { DeviceType } from '@portals/api/organizations';
import { RowMenuItems } from '@portals/table';
import { RowMenuWrapperType } from '@portals/types';

import { useDeviceMenuItems } from './hooks';

interface DevicesListRowMenuProps {
  rowData: DeviceType;
  wrapperProps: RowMenuWrapperType;
}

export const DevicesListRowMenu = ({
  rowData,
  wrapperProps,
}: DevicesListRowMenuProps) => {
  const menuItems = useDeviceMenuItems({
    device: rowData,
  });

  return <RowMenuItems items={menuItems} wrapperProps={wrapperProps} />;
};
