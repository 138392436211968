import {
  DEVICE_MODELS_API_URL,
  deviceModelsQueryKeys,
} from './device-models.constants';
import {
  DeviceModelAndPartnerSearchResultType,
  DeviceModelType,
  PartnerWithDeviceModelsType,
} from './device-models.types';
import { useApiQuery } from '../../hooks';

export function useDeviceModels() {
  return useApiQuery<Array<DeviceModelType>>(
    DEVICE_MODELS_API_URL,
    deviceModelsQueryKeys.base
  );
}

export function useSearchDeviceModelsAndPartners(searchTerm: string) {
  const url = `${DEVICE_MODELS_API_URL}/search?q=${searchTerm}`;

  return useApiQuery<Array<DeviceModelAndPartnerSearchResultType>>(
    url,
    deviceModelsQueryKeys.searchDeviceModelsAndPartners(searchTerm),
    {
      staleTime: 5000,
      cacheTime: 5000,
      enabled: searchTerm.length >= 2,
    }
  );
}

export function usePartnerWithDeviceModels(partnerId: string) {
  return useApiQuery<PartnerWithDeviceModelsType>(
    `${DEVICE_MODELS_API_URL}/partner_device_models?partner_id=${partnerId}`,
    deviceModelsQueryKeys.partnerWithDeviceModels(partnerId),
    {
      enabled: !!partnerId,
    }
  );
}
