import {
  Modal,
  Stack,
  Text,
  ModalProps as MantineModalProps,
  createStyles,
  Box,
  Card,
} from '@mantine/core';
import React, { useMemo, useState } from 'react';

import {
  DeviceModelType,
  SpaceType,
  usePartnerWithDeviceModels,
} from '@portals/api/organizations';
import { SearchInput } from '@portals/core';
import {
  DeviceAvatar,
  ModalProps,
  deviceClaimingDefaultBannerSrc,
  PartnerAvatar,
} from '@portals/framework';
import { useOpenModal } from '@portals/redux';

import { AddDeviceModalProps } from './AddDeviceModal';

export interface SelectModelFromPartnerModalProps
  extends ModalProps<{ partnerId: string; spaceId?: SpaceType['id'] }> {}

export function SelectModelFromPartnerModal({
  closeMe,
  data: { partnerId, spaceId },
}: SelectModelFromPartnerModalProps) {
  const { classes } = useStyles();

  const [searchTerm, setSearchTerm] = useState('');

  const partnerWithDeviceModels = usePartnerWithDeviceModels(partnerId);
  const openModal = useOpenModal();

  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  const models = useMemo(
    () =>
      partnerWithDeviceModels.data?.device_models.filter(
        (deviceModel: DeviceModelType) =>
          deviceModel.model.toLowerCase().includes(lowerCaseSearchTerm)
      ),
    [lowerCaseSearchTerm, partnerWithDeviceModels.data?.device_models]
  );

  return (
    <Modal opened onClose={closeMe} styles={modalStyles} title="Add new device">
      <Stack spacing="xl" w="100%">
        <Box pos="relative" mb="xxl">
          <img
            src={
              partnerWithDeviceModels.data?.partner.banner ||
              deviceClaimingDefaultBannerSrc
            }
            width="100%"
            height={170}
            alt="banner"
            className={classes.banner}
          />

          <Box className={classes.logoContainer}>
            <PartnerAvatar
              partnerName={partnerWithDeviceModels.data?.partner.display_name}
              size={94}
              src={partnerWithDeviceModels.data?.partner.logo}
            />
          </Box>
        </Box>

        <Text size={24} align="center" px="md" data-testid="partner-brand-name">
          {partnerWithDeviceModels.data?.partner.display_name}
        </Text>
        <Stack align="center" spacing="xl" px="md">
          <SearchInput
            radius="md"
            size="md"
            mx="auto"
            maw={430}
            w="100%"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClear={() => setSearchTerm('')}
          />

          {models && models.length > 0 ? (
            <div className={classes.grid}>
              {models.map((model) => (
                <Card
                  key={model.id}
                  radius="lg"
                  padding={0}
                  className={classes.modelContainer}
                  onClick={() => {
                    closeMe();
                    openModal<AddDeviceModalProps['data']>('AddDeviceModal', {
                      spaceId,
                      selectedModel: model,
                    });
                  }}
                >
                  <Card.Section>
                    <DeviceAvatar
                      img={model.user_settings?.media?.img}
                      icon={model.user_settings?.icon}
                      size={280}
                      imageProps={{ style: { objectFit: 'contain' } }}
                    />
                  </Card.Section>

                  <Card.Section withBorder>
                    <Text
                      p="xl"
                      size="lg"
                      color="blue_gray.9"
                      align="center"
                      data-testid={`model-name-${model.model}`}
                    >
                      {model.model}
                    </Text>
                  </Card.Section>
                </Card>
              ))}
            </div>
          ) : null}
        </Stack>
      </Stack>
    </Modal>
  );
}

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  content: {
    minWidth: '100%',
    minHeight: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
  },

  body: {
    backgroundColor: theme.colors.gray[0],
    padding: 0,
    paddingBottom: theme.spacing.xl,
  },

  header: {
    padding: theme.spacing.xl,
  },
});

const useStyles = createStyles((theme) => ({
  modelContainer: {
    border: `1px solid ${theme.colors.gray[2]}`,
    cursor: 'pointer',

    '&:hover': {
      boxShadow: '0px 5px 12px 0px rgba(38, 50, 56, 0.13)',
    },
  },

  modelName: {
    padding: theme.spacing.xl,
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },

  logoContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(40%)',
  },

  banner: {
    objectFit: 'cover',
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 280px)',
    gap: theme.spacing.xl,
    maxWidth: '90%',
  },
}));
