import { forEach } from 'lodash/fp';
import React, { FC, ReactNode, useMemo } from 'react';
import {
  matchPath,
  Route,
  Routes as ReactRouterRoutes,
  useLocation,
} from 'react-router-dom';

import { useAuth } from '@portals/redux';
import { CommonConfigurationType } from '@portals/types';
import { ScrollToTop } from '@portals/ui';

import { authRoutes } from './routes.constants';
import { getChildRoutes } from './routes.utils';
import { Enable2FANotification } from '../components/Enable2FANotification';
import { ErrorBoundary } from '../components/ErrorBoundary';
import Modals from '../components/Modals';
import { NetworkStatusNotification } from '../components/NetworkStatusNotification';
import { usePermissionAccess } from '../components/permission-access/use-permission-access';
import { useAppConfig } from '../context';
import { useCommonConfig } from '../hooks/portal-config';
import { useHasSupportSeat } from '../hooks/support-seats';
import { useAnalytics } from '../hooks/use-analytics';
import AuthLayout from '../layouts/Auth';
import { DashboardLayout } from '../layouts/Dashboard';
import { ExternalStoreLayout } from '../layouts/ExternalStoreLayout';
import OnboardingLayout from '../layouts/Onboarding';
import { UnsubscribeLayout } from '../layouts/Unsubscribe';
import { Page404 } from '../pages/auth/Page404';
import { Referral } from '../pages/auth/Referral';

enum LayoutTypeEnum {
  Auth = 'Auth',
  ExternalStore = 'ExternalStore',
  Dashboard = 'Dashboard',
  Onboarding = 'Onboarding',
  Unsubscribe = 'Unsubscribe',
}

function AdjustedRoutes<
  TCommonConfigurationType extends CommonConfigurationType
>() {
  const location = useLocation();
  const { extraLayout, routes } = useAppConfig<TCommonConfigurationType>();

  const configuration = useCommonConfig();
  const { canView, canEdit, isAdmin } = usePermissionAccess();
  const hasSupportSeat = useHasSupportSeat();
  const authentication = useAuth();

  const [auth, dashboard, shop = null, onboarding = null] = useMemo(() => {
    const appRoutes = [
      getChildRoutes<TCommonConfigurationType>({
        routes: routes?.auth || authRoutes,
        configuration: configuration.data as TCommonConfigurationType,
        canView,
        canEdit,
        isAdmin,
        hasSupportSeat,
      }),
    ];

    const dashboardRoutes: ReactNode[] = [];

    forEach((dashboardRoute) => {
      if (
        dashboardRoute.canAccessRoute &&
        !dashboardRoute.canAccessRoute({
          configuration: configuration.data as TCommonConfigurationType,
          canView,
          canEdit,
          isAdmin,
          hasSupportSeat,
        })
      ) {
        return;
      }

      dashboardRoutes.push(
        getChildRoutes<TCommonConfigurationType>({
          routes: dashboardRoute.childRoutes,
          configuration: configuration.data as TCommonConfigurationType,
          canView,
          canEdit,
          isAdmin,
          hasSupportSeat,
        })
      );
    }, routes?.dashboard);

    if (routes?.routeModals) {
      forEach((modalRoute) => {
        if (
          modalRoute.canAccessRoute &&
          !modalRoute.canAccessRoute({
            configuration: configuration.data as TCommonConfigurationType,
            canView,
            canEdit,
            isAdmin,
            hasSupportSeat,
          })
        ) {
          return;
        }

        dashboardRoutes.push(
          getChildRoutes<TCommonConfigurationType>({
            routes: [modalRoute],
            configuration: configuration.data as TCommonConfigurationType,
            canView,
            canEdit,
            isAdmin,
            hasSupportSeat,
          })
        );
      }, routes.routeModals);
    }

    if (routes?.routePanels) {
      forEach((panelRoute) => {
        if (
          panelRoute.canAccessRoute &&
          !panelRoute.canAccessRoute({
            configuration: configuration.data as TCommonConfigurationType,
            canView,
            canEdit,
            isAdmin,
            hasSupportSeat,
          })
        ) {
          return;
        }

        dashboardRoutes.push(
          getChildRoutes<TCommonConfigurationType>({
            routes: [panelRoute],
            configuration: configuration.data as TCommonConfigurationType,
            canView,
            canEdit,
            isAdmin,
            hasSupportSeat,
          })
        );
      }, routes.routePanels);
    }

    appRoutes.push([dashboardRoutes]);

    if (routes?.externalStore) {
      appRoutes.push(
        getChildRoutes<TCommonConfigurationType>({
          routes: routes.externalStore,
          configuration: configuration.data as TCommonConfigurationType,
          canView,
          canEdit,
          isAdmin,
          hasSupportSeat,
        })
      );
    } else {
      // TODO: Refactor to const { auth, dashboard, ... } = useMemo();
      appRoutes.push(null);
    }

    if (routes?.onboarding) {
      appRoutes.push(
        getChildRoutes<TCommonConfigurationType>({
          routes: routes.onboarding,
          configuration: configuration.data as TCommonConfigurationType,
          canView,
          canEdit,
          isAdmin,
          hasSupportSeat,
        })
      );
    }

    return appRoutes;
  }, [routes, configuration.data, canView, canEdit, isAdmin, hasSupportSeat]);

  const layoutType = useMemo(() => {
    function matcher(path: `/${string}`) {
      return matchPath({ path, end: false }, location.pathname);
    }

    if (matcher('/auth')) {
      return LayoutTypeEnum.Auth;
    } else if (matcher('/store') && !authentication) {
      return LayoutTypeEnum.ExternalStore;
    } else if (matcher('/unsubscribe')) {
      return LayoutTypeEnum.Unsubscribe;
    } else if (matcher('/onboarding')) {
      return LayoutTypeEnum.Onboarding;
    } else {
      return LayoutTypeEnum.Dashboard;
    }
  }, [authentication, location.pathname]);

  const adjustedRoutes = useMemo(() => {
    let Layout: FC<{ children: ReactNode }>;
    let adjustedRoutes: ReactNode;

    if (layoutType === LayoutTypeEnum.Auth) {
      Layout = extraLayout?.authLayout || AuthLayout;
      adjustedRoutes = auth;
    } else if (layoutType === LayoutTypeEnum.ExternalStore) {
      Layout = extraLayout?.externalStoreLayout || ExternalStoreLayout;
      adjustedRoutes = shop;
    } else if (layoutType === LayoutTypeEnum.Unsubscribe) {
      Layout = UnsubscribeLayout;
      adjustedRoutes = null;
    } else if (layoutType === LayoutTypeEnum.Onboarding) {
      Layout = extraLayout?.onboardingLayout || OnboardingLayout;
      adjustedRoutes = onboarding;
    } else {
      Layout = extraLayout?.dashboardLayout || DashboardLayout;
      adjustedRoutes = dashboard;
    }

    return (
      <Layout>
        <ErrorBoundary location={location}>
          <ReactRouterRoutes>
            {adjustedRoutes}

            <Route path="*" element={<Page404 />} />
          </ReactRouterRoutes>
        </ErrorBoundary>
      </Layout>
    );
  }, [layoutType, location, extraLayout, auth, shop, onboarding, dashboard]);

  return <ScrollToTop>{adjustedRoutes}</ScrollToTop>;
}

export function Routes<
  TCommonConfigurationType extends CommonConfigurationType
>() {
  useAnalytics();

  return (
    <>
      <Referral />

      <AdjustedRoutes<TCommonConfigurationType> />

      <Modals />

      <NetworkStatusNotification />

      <Enable2FANotification />
    </>
  );
}
