import { Button, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

import { GroupsDetailsType } from '@portals/api/organizations';
import { useUsers } from '@portals/api/ui';
import {
  ModalBody,
  ModalCenteredMediaLayout,
  ModalFooter,
} from '@portals/core';
import { Members, ModalProps, UsersMultiSelect } from '@portals/framework';

export interface AddInternalUsersToNewGroupModalProps
  extends ModalProps<{
    alreadyAddedUserIds: string[];
    onSubmit: (usersToAdd: GroupsDetailsType['users']) => void;
  }> {}

export function AddInternalUsersToNewGroupModal({
  closeMe,
  data: { alreadyAddedUserIds, onSubmit },
}: AddInternalUsersToNewGroupModalProps) {
  const users = useUsers();

  const form = useForm<{ userIds: string[] }>({
    initialValues: {
      userIds: [],
    },
  });

  const options = (users.data || [])
    .filter(
      (user) => !alreadyAddedUserIds.includes(user.id) && !user.deactivated_at
    )
    .map((user) => ({
      value: user.id,
      label: user.name,
      email: user.email,
    }));

  const handleSubmit = (values: typeof form.values) => {
    if (!users.data) return;

    const usersToAdd: GroupsDetailsType['users'] = users.data
      .filter((user) => values.userIds.includes(user.id))
      .map((user) => ({
        id: user.id,
        name: user.name,
        email: user.email,
        is_external: false,
        settings: user.settings,
      }));

    onSubmit(usersToAdd);
    closeMe();
  };

  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      title="Add Internal Members"
      description="Please note: External users can be added after the group has been created"
      media={<Members />}
    >
      <LoadingOverlay visible={users.isFetching} />

      <form noValidate onSubmit={form.onSubmit(handleSubmit)}>
        <ModalBody>
          <UsersMultiSelect data={options} {...form.getInputProps('userIds')} />
        </ModalBody>

        <ModalFooter grow>
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>
          <Button
            type="submit"
            data-testid="modal-add-members-button"
            disabled={form.values.userIds.length === 0}
          >
            Add Members
          </Button>
        </ModalFooter>
      </form>
    </ModalCenteredMediaLayout>
  );
}
