import {
  ActionIcon,
  Box,
  createStyles,
  Group,
  LoadingOverlay,
  MantineSize,
  MantineThemeColors,
  Menu,
  MenuProps,
  Text,
  Tooltip,
} from '@mantine/core';
import { map } from 'lodash/fp';
import React, { PropsWithChildren, ReactElement } from 'react';

import { ComponentRendererType, RowMenuWrapperType } from '@portals/types';

import RowMenuToggle from './RowMenuToggle';

export interface RowMenuItem {
  id: string;
  label: string;
  Icon: ComponentRendererType;
  color?: keyof Pick<MantineThemeColors, 'red' | 'gray'>;
  onClick: () => void;
  disabled?: boolean;
  withTopDivider?: boolean;
  withBottomDivider?: boolean;
  Wrapper?: ({ children }: PropsWithChildren<any>) => ReactElement;
}

export interface RowMenuItemsProps {
  items: Array<RowMenuItem>;
  labelSize?: MantineSize;
  isLoading?: boolean;
  wrapperProps: RowMenuWrapperType;
  menuProps?: Omit<MenuProps, 'children'>;
}

const ICON_SIZE = 18 as const;
const SINGLE_ITEM_ICON_SIZE = 24 as const;

function DefaultWrapper({ children }) {
  return <Group sx={{ height: '100%' }}>{children}</Group>;
}

function RowMenuItems({
  items,
  labelSize = 'xs',
  isLoading,
  wrapperProps,
  menuProps = {},
}: RowMenuItemsProps) {
  const { classes } = useStyles();
  const { isHovered, isMenuOpen, setIsMenuOpen } = wrapperProps;

  if (!isHovered && !isMenuOpen) return null;

  if (isLoading) return <LoadingOverlay visible />;

  if (items.length === 1) {
    const {
      id,
      Icon,
      color,
      label,
      onClick,
      disabled,
      Wrapper = DefaultWrapper,
    } = items[0];

    return (
      <Box
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
        className={classes.container}
      >
        <Box className={classes.tooltipContainer}>
          <Wrapper>
            <Tooltip
              id={id}
              label={label}
              position="bottom"
              withArrow
              withinPortal
              fz="xs"
              py="xs"
              px="md"
              bg="blue_gray.9"
              data-testid={`row-menu-item-${id}`}
            >
              <ActionIcon
                w={SINGLE_ITEM_ICON_SIZE}
                h={SINGLE_ITEM_ICON_SIZE}
                disabled={disabled}
                color={color ? `${color}.4` : 'gray.9'}
                onClick={disabled ? null : onClick}
              >
                <Icon />
              </ActionIcon>
            </Tooltip>
          </Wrapper>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      className={classes.container}
    >
      <Menu
        onOpen={() => setIsMenuOpen(true)}
        onClose={() => setIsMenuOpen(false)}
        closeOnItemClick={false}
        withinPortal={true}
        position="bottom-end"
        {...menuProps}
      >
        <Menu.Target>
          <Tooltip label="Actions">
            <div>
              <RowMenuToggle isOpen={isMenuOpen} />
            </div>
          </Tooltip>
        </Menu.Target>

        <Menu.Dropdown>
          {map(
            ({
              id,
              Icon,
              color,
              label,
              onClick,
              disabled,
              Wrapper = DefaultWrapper,
              withTopDivider,
              withBottomDivider,
            }) => (
              <React.Fragment key={id}>
                {withTopDivider && <Menu.Divider />}

                <Menu.Item
                  onClick={onClick}
                  disabled={disabled}
                  color={color ? `${color}.4` : 'gray.9'}
                  data-testid={`row-menu-item-${id}`}
                >
                  <Wrapper>
                    <Group spacing="sm">
                      <Icon width={ICON_SIZE} height={ICON_SIZE} />

                      <Text size={labelSize}>{label}</Text>
                    </Group>
                  </Wrapper>

                  {withBottomDivider && <Menu.Divider />}
                </Menu.Item>
              </React.Fragment>
            ),
            items
          )}
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'sticky',
    right: 0,
    width: 0,
  },
  tooltipContainer: {
    position: 'absolute',
    transform: 'translateX(-100%) translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
    justifyContent: 'center',
    background: theme.fn.linearGradient(
      90,
      'transparent',
      'white',
      'white',
      'white'
    ),
    width: 50,
    height: 56,
  },
}));

export default RowMenuItems;
