import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { QueryOptions } from '../types';
import { fetchApiRequest, useRequestOptions } from '../utils';

/**
 * Custom hook for making API queries.
 *
 * @template T - The type of the response data.
 * @param {string} url - The URL to make the API request to.
 * @param {string[]} key - The query key used for caching and invalidation.
 * @param {QueryOptions<T>} [queryOptions] - Additional options for the query.
 * @returns {UseQueryResult<T>} - The result of the API query.
 */
export function useApiQuery<T>(
  url: string,
  key: string[],
  queryOptions: QueryOptions<T> = {}
): UseQueryResult<T> {
  const { url: adjustedUrl, options } = useRequestOptions({
    url,
  });

  return useQuery({
    queryKey: key,
    queryFn: () => fetchApiRequest(adjustedUrl, options),
    // Adding meta for better error handling
    meta: {
      baseUrl: adjustedUrl,
      method: options?.method || 'GET',
    },
    ...queryOptions,
  });
}
