import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { toastrError } from '@portals/redux/actions/toastr';
import { UseCheckout, UsePaginatedTableApiQuery } from '@portals/types';

import { getOrderItemPaidIntervalUnits } from './order.utils';
import { ORDERS_API_URL, ordersQueryKeys } from './orders.constants';
import {
  OrderPriceQuotePreviewType,
  OrderSummaryType,
  OrderType,
  UseOrderPreviewParams,
  UsePriceQuotePreviewItem,
} from './orders.types';
import { useApiQuery } from '../../hooks';
import { ServerError, ServerSuccess } from '../../types';
import {
  fetchApiRequest,
  usePaginatedTableApiQuery,
  useRequestOptions,
} from '../../utils';
import { devicesQueryKeys } from '../devices';
import { purchasedProductsQueryKeys } from '../purchased-products/purchased-products.constants';
import { subscriptionsQueryKeys } from '../subscriptions';

export function usePaginatedOrders(
  tableState: UsePaginatedTableApiQuery<OrderSummaryType>['tableState'],
  columns: UsePaginatedTableApiQuery<OrderSummaryType>['columns'],
  baseUrl: string = ORDERS_API_URL,
  queryKey?: Array<string>
) {
  return usePaginatedTableApiQuery<OrderSummaryType>({
    baseUrl,
    queryKey: queryKey
      ? [...queryKey, baseUrl, tableState]
      : [...ordersQueryKeys.list(tableState), baseUrl, tableState],
    tableState,
    columns,
    queryOptions: {
      staleTime: 0,
    },
  });
}

export function useOrderById(orderId: string): UseQueryResult<OrderType> {
  return useApiQuery<OrderType>(
    `${ORDERS_API_URL}/${orderId}`,
    ordersQueryKeys.detail(orderId),
    { enabled: !!orderId }
  );
}

export function useOrderPreview(order: UseOrderPreviewParams) {
  const { url: adjustedUrl, options } = useRequestOptions({
    url: `${ORDERS_API_URL}/preview`,
    method: 'POST',
    data: order,
  });

  return useQuery({
    queryKey: ordersQueryKeys.preview(order),
    queryFn: () => fetchApiRequest(adjustedUrl, options),
    keepPreviousData: true,
    meta: {
      baseUrl: `${ORDERS_API_URL}/preview`,
      method: 'POST',
    },
  });
}

export function useCreateOrder(
  order: UseOrderPreviewParams,
  checkout: UseCheckout
) {
  const queryClient = useQueryClient();
  const { creditCard, address, paymentMethod, note } = checkout;
  const { url, options } = useRequestOptions({
    url: ORDERS_API_URL,
    method: 'POST',
  });

  return useMutation<OrderType, { error: string }>({
    mutationFn: () => {
      return fetchApiRequest(url, {
        ...options,
        body: JSON.stringify({
          ...order,
          items: order.items.map(
            ({
              quantity,
              selected_payment_interval,
              store_listing_id,
              price,
            }) => ({
              quantity,
              price,
              store_listing_id,
              selected_payment_interval,
              prepaid_interval_units: getOrderItemPaidIntervalUnits(
                selected_payment_interval,
                paymentMethod.selected
              ),
            })
          ),
          note: note.selected,
          payment_method: paymentMethod.selected,
          credit_card_id: creditCard.selected,
          billing_address_id: address.billing.selected,
          shipping_address_id: address.shipping.selected,
        }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(purchasedProductsQueryKeys.base);
      queryClient.invalidateQueries(ordersQueryKeys.all);
      queryClient.invalidateQueries(subscriptionsQueryKeys.base);
      queryClient.invalidateQueries(devicesQueryKeys.licenses.global);
    },
    meta: {
      mutationName: 'useCreateOrder',
      baseUrl: ORDERS_API_URL,
      method: 'POST',
    },
  });
}

export const useOrderPriceQuotePreview = (
  order: UseOrderPreviewParams<UsePriceQuotePreviewItem>
) => {
  const { url, options } = useRequestOptions({
    url: ORDERS_API_URL,
    method: 'POST',
    data: order,
  });

  return useQuery<OrderPriceQuotePreviewType>({
    queryKey: ordersQueryKeys.priceQuotesPreview(order),
    queryFn: () => fetchApiRequest(`${url}/price_quote_preview`, options),
    keepPreviousData: true,
    meta: {
      baseUrl: `${ORDERS_API_URL}/price_quote_preview`,
      method: 'POST',
    },
  });
};

export function useUpdateOrderShipmentToDelivered() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { url: baseUrl, options } = useRequestOptions({
    url: ORDERS_API_URL,
    method: 'POST',
  });

  return useMutation<ServerSuccess, ServerError, string>({
    mutationFn: (orderId) =>
      fetchApiRequest(`${baseUrl}/${orderId}/delivered`, options),
    onSuccess: () => {
      queryClient.invalidateQueries(ordersQueryKeys.all);
    },
    onError: ({ error }) => {
      dispatch(toastrError(error));
    },
    meta: {
      mutationName: 'useUpdateOrderShipmentToDelivered',
      baseUrl: `${ORDERS_API_URL}/:id/delivered`,
      method: 'POST',
    },
  });
}
