import {
  Accordion,
  AccordionProps,
  Box,
  Button,
  Group,
  Progress,
  Stack,
  Text,
} from '@mantine/core';
import { filter, size } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  GETTING_STARTED_TOURS,
  GettingStartedToursNamesEnum,
  useCecPartners,
  useConfig,
  useFirstClaimedDevice,
  useOnboardingTours,
  useOrganizationConfig,
  usePortalCapabilities,
  usePortalConfig,
  useWelcomeVideoPlayed,
} from '@portals/api/organizations';
import { useCurrentUser } from '@portals/api/ui';
import {
  isCustomerFeatureAll,
  isCustomerFeatureLabOnly,
  Page403,
  usePermissionAccess,
} from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { ReactComponent as BoxIcon } from '@portals/icons/bulk/box-1.svg';
import { ReactComponent as Data } from '@portals/icons/bulk/data.svg';
import { ReactComponent as TrendUp } from '@portals/icons/bulk/trend-up.svg';
import { ReactComponent as CloseCircle } from '@portals/icons/linear/close-circle.svg';
import { ReactComponent as VideoCircle } from '@portals/icons/linear/video-circle.svg';
import { useOpenModal } from '@portals/redux';
import { isGettingStartedToursDone } from '@portals/utils';

import { TourItem } from './TourItem';

export function Setup() {
  const currentUser = useCurrentUser();
  const onboardingTours = useOnboardingTours();
  const { mutate: mutateWelcomeVideoPlayed } = useWelcomeVideoPlayed();
  const openModal = useOpenModal();
  const openRouteModal = useOpenRouteModal();
  const firstClaimedDevice = useFirstClaimedDevice();
  const navigate = useNavigate();
  const config = useConfig();
  const { isAdmin } = usePermissionAccess();
  const portalCapabilities = usePortalCapabilities();
  const organizationConfig = useOrganizationConfig();
  const portalConfig = usePortalConfig();
  const cecPartners = useCecPartners();
  const location = useLocation();

  const adminEmail = organizationConfig?.admin_user_email || '';

  const stepsLeft = useMemo(() => {
    if (!onboardingTours?.getting_started) return GETTING_STARTED_TOURS.length;

    return size(
      filter(
        (step) => !onboardingTours?.getting_started[step],
        GETTING_STARTED_TOURS
      )
    );
  }, [onboardingTours]);

  const showOnboardingVideo =
    (isCustomerFeatureAll(portalCapabilities?.onboarding_video) ||
      isCustomerFeatureLabOnly(
        portalCapabilities.onboarding_video,
        organizationConfig.lab
      )) &&
    !onboardingTours?.isWelcomeVideoPlayed;

  const hasConnectedCecPartners = cecPartners.data?.some(
    (cecPartner) => cecPartner.connected
  );

  const showCecPartnersModal =
    portalConfig.isFetched &&
    cecPartners.isFetched &&
    portalConfig.data?.cec_enabled &&
    !hasConnectedCecPartners &&
    !showOnboardingVideo &&
    location.state?.['afterLogin'];

  useEffect(() => {
    if (showCecPartnersModal) {
      openRouteModal({ modalId: 'connect' });
    }

    if (showOnboardingVideo) {
      openModal('AdminWelcomeVideoModal');
      mutateWelcomeVideoPlayed();
    }
  }, [
    onboardingTours?.isWelcomeVideoPlayed,
    openModal,
    openRouteModal,
    showCecPartnersModal,
    showOnboardingVideo,
    mutateWelcomeVideoPlayed,
  ]);

  const calcStepsProgress = () => {
    const stepsCount = GETTING_STARTED_TOURS.length;

    return 100 - (stepsLeft / stepsCount) * 100;
  };

  if (!isAdmin) {
    return (
      <Page403
        action={
          <Button onClick={() => window.open(`mailto:${adminEmail}`, '_blank')}>
            Contact Admin
          </Button>
        }
      />
    );
  }

  return (
    <Stack bg="gray.0" mih="100%" p={56} align="center" spacing="xxl">
      <Stack align="center" spacing="xs">
        <Text size={24} color="gray.9" data-testid="setup-page-title">
          Welcome {currentUser.data?.name}
        </Text>
        <Text color="gray.7">
          Use this personalized guide to get your organization up and running.
        </Text>
        <Group spacing="xs" mt="xs">
          {!onboardingTours?.dismiss &&
            !isGettingStartedToursDone(config.data) && (
              <Button
                size="xs"
                data-testid="dismiss-guides-button"
                variant="default"
                leftIcon={<CloseCircle width={14} height={14} />}
                onClick={() => {
                  openModal('DismissSetupGuideModal');
                }}
              >
                Dismiss guides
              </Button>
            )}
          {showOnboardingVideo ? (
            <Button
              size="xs"
              variant="default"
              leftIcon={<VideoCircle width={14} height={14} />}
              onClick={() => {
                openModal('AdminWelcomeVideoModal');
              }}
            >
              Watch video
            </Button>
          ) : null}
        </Group>
      </Stack>

      <Box w="clamp(768px, 60%, 900px)">
        <Accordion
          multiple
          variant="separated"
          defaultValue={['getting-started']}
          styles={accordionStyles}
        >
          <Accordion.Item value="getting-started">
            <Accordion.Control>
              <Group position="apart">
                <Text size="lg" color="gray.9">
                  Getting started
                </Text>
                <Text color="gray.5" data-testid="steps-left-counter" size="sm">
                  {stepsLeft} steps left
                </Text>
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              <Box>
                <Progress size="xs" value={calcStepsProgress()} />
                <Box px="xl">
                  <TourItem
                    title="Set up your spaces"
                    description={
                      "Customize your organization's spaces by arranging them based on your preferred method – whether it's by rooms, locations, business units, or any other relevant categorization."
                    }
                    icon={<Data />}
                    isCompleted={
                      !!firstClaimedDevice.data ||
                      !!onboardingTours?.getting_started?.setup_spaces_completed
                    }
                    buttonTitle="Setup Spaces"
                    disabled={false}
                    onClick={() =>
                      navigate(
                        `/overview/?active_tour=${GettingStartedToursNamesEnum.SetupSpaces}`
                      )
                    }
                    replay={() => {
                      navigate(
                        `/overview/?active_tour=${GettingStartedToursNamesEnum.SetupSpaces}`
                      );
                    }}
                  />

                  <TourItem
                    title="Claim your first device"
                    description="Quickly claim your first device, ensuring seamless integration into your system."
                    icon={<BoxIcon />}
                    isCompleted={!!firstClaimedDevice.data}
                    buttonTitle="Claim Device"
                    disabled={
                      !onboardingTours?.getting_started?.setup_spaces_completed
                    }
                    onClick={() =>
                      navigate(
                        `/overview/?active_tour=${GettingStartedToursNamesEnum.ClaimDevice}`
                      )
                    }
                    replay={() => {
                      navigate(
                        `/overview/?active_tour=${GettingStartedToursNamesEnum.ClaimDevice}`
                      );
                    }}
                  />

                  <TourItem
                    title="Monitor and manage devices"
                    description="Learn how to effectively monitor, manage, and configure your devices, empowering you to keep track of their performance and make necessary adjustments as needed."
                    icon={<TrendUp />}
                    isCompleted={
                      !!onboardingTours?.getting_started?.monitoring_completed
                    }
                    buttonTitle="Monitor Device"
                    disabled={
                      !firstClaimedDevice.data &&
                      !onboardingTours?.getting_started?.claim_device_completed
                    }
                    onClick={() =>
                      navigate(
                        `/overview/?active_tour=${GettingStartedToursNamesEnum.Monitoring}`
                      )
                    }
                    replay={() => {
                      navigate(
                        `/overview/?active_tour=${GettingStartedToursNamesEnum.Monitoring}`
                      );
                    }}
                  />
                </Box>
              </Box>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>
    </Stack>
  );
}

const accordionStyles: AccordionProps['styles'] = (theme) => ({
  control: {
    backgroundColor: theme.white,
    paddingInline: theme.spacing.xl,
    borderRadius: theme.radius.lg,
  },
  content: {
    padding: 0,
  },
  label: {
    paddingBlock: theme.spacing.xxl,
  },
  chevron: {
    svg: {
      width: '24px !important',
      height: '24px !important',
    },
  },
  item: {
    border: 'none',
  },
});
