import {
  Button,
  createStyles,
  Group,
  Image,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import React, { useMemo } from 'react';

import { useRevokeDeviceLicense } from '@portals/api/organizations';
import { TextWithTooltip } from '@portals/core';
import { useConfirmationModal } from '@portals/framework';
import { ReactComponent as Gallery } from '@portals/icons/bold/gallery.svg';
import { LicenseType } from '@portals/types';

import { AssignedLicenseCardFooter } from './AssignedLicenseCardFooter';

interface LicenseCardProps {
  deviceId: string;
  license: LicenseType;
}

export function AssignedLicenseCard({ deviceId, license }: LicenseCardProps) {
  const { classes } = useStyles();

  const revokeDeviceLicense = useRevokeDeviceLicense();
  const asyncConfirm = useConfirmationModal();

  const onRevokeLicense = async () => {
    const isConfirmed = await asyncConfirm({
      description: `Are you sure you want to revoke "${license.product_name}"`,
    });

    if (isConfirmed) {
      revokeDeviceLicense.mutate({ deviceId, licenseId: license.id });
    }
  };

  const shouldDisplayRemoveButton = useMemo(() => {
    if (!license.transferable) return false;

    if (license.state === 'remove') return false;

    if (license.state === 'disable') return false;

    if (license.state === 'disabled') return false;

    return true;
  }, [license.state, license.transferable]);

  return (
    <Paper radius="md" p="xl" withBorder>
      <Stack spacing="sm" className={classes.contentContainer}>
        <Group spacing="sm" position="apart" align="flex-start" noWrap>
          <Image
            radius="md"
            width={80}
            height={80}
            src={license.product_image ?? undefined}
            withPlaceholder
            placeholder={<Gallery />}
            styles={{
              root: { width: 80, height: 80 },
            }}
          />

          {shouldDisplayRemoveButton && (
            <Button
              size="xs"
              variant="default"
              onClick={onRevokeLicense}
              data-testid="remove-license-button"
            >
              Remove
            </Button>
          )}
        </Group>

        <Stack spacing={4}>
          <TextWithTooltip
            lineClamp={1}
            className={classes.productName}
            data-testid={`assigned-license-${license.product_name}`}
          >
            {license.product_name}
          </TextWithTooltip>
          <Text className={classes.partnerName}>
            by {license.partner_display_name}
          </Text>
        </Stack>

        <Text lineClamp={2} className={classes.productSubtitle}>
          {license.product_subtitle}
        </Text>

        <AssignedLicenseCardFooter
          licenseState={license.state}
          deviceErrorMessage={license.device_error_message}
        />
      </Stack>
    </Paper>
  );
}

const useStyles = createStyles((theme) => ({
  contentContainer: {
    height: '100%',
  },
  productName: {
    fontSize: theme.fontSizes.lg,
    color: theme.colors.gray[9],
  },
  partnerName: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.gray[5],
  },
  productSubtitle: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[8],
  },
}));
