import {
  CloseButton,
  createStyles,
  Loader,
  TextInput,
  TextInputProps,
} from '@mantine/core';
import React, { forwardRef } from 'react';

import { ReactComponent as SearchNormal2 } from '@portals/icons/linear/search-normal 2.svg';

interface SearchInputProps extends TextInputProps {
  onClear: () => void;
  loading?: boolean;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ onClear, loading, value, className, ...textInputProps }, ref) => {
    const { classes, cx } = useStyles();

    const rightSectionRenderer = () => {
      if (loading) {
        return <Loader size="xs" />;
      }

      if (value) {
        return (
          <CloseButton
            onClick={onClear}
            variant="transparent"
            className="search-input-icon"
          />
        );
      }

      return <SearchNormal2 className={classes.searchIcon} />;
    };

    return (
      <TextInput
        ref={ref}
        placeholder="Search..."
        size="xs"
        className={cx(classes.root, className)}
        rightSection={rightSectionRenderer()}
        value={value}
        {...textInputProps}
        data-testid="search-field-input"
      />
    );
  }
);

const useStyles = createStyles((theme) => ({
  root: {
    width: 300,
  },
  searchIcon: {
    color: theme.colors.gray[5],
    height: 14,
    width: 14,
  },
}));
