import {
  ActionIcon,
  Anchor,
  Box,
  createStyles,
  Group,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { DeviceDetailsType } from '@portals/api/organizations';
import { TextWithTooltip } from '@portals/core';
import { ReactComponent as ParentArrowIcon } from '@portals/icons/linear/parent-arrow.svg';
import { ReactComponent as Snooze } from '@portals/icons/linear/snooze.svg';
import { DeviceType as CommonDeviceType } from '@portals/types';
import { prettyTime } from '@portals/utils';

import { DeviceName } from './DeviceName';
import { DeviceAvatar } from '../../../../components/DeviceAvatar';
import { DeviceAvatarWithIndicator } from '../../../../components/DeviceAvatarWithIndicator';
import { DeviceCecPartnerLogoOrName } from '../../../../components/DeviceCecPartnerLogoOrName';
import { DeviceStatusBadge } from '../../../../components/DeviceStatusBadge';
import { useOpenRouteModal } from '../../../route-modals.hooks';
import { useDeviceContext } from '../context';

interface HeaderProps<TDevice extends DeviceDetailsType | CommonDeviceType> {
  device: TDevice;
}

export function Header<TDevice extends DeviceDetailsType | CommonDeviceType>({
  device,
}: HeaderProps<TDevice>) {
  const { classes, theme } = useStyles();
  const deviceContext = useDeviceContext();

  const openRouteModal = useOpenRouteModal();

  const parentDevice = (device as DeviceDetailsType).parent_device;

  return (
    <Stack spacing="xl">
      {parentDevice && (
        <Group
          noWrap
          spacing="xs"
          className={classes.parentDevice}
          onClick={() =>
            openRouteModal({
              modalId: 'device',
              pathParams: [parentDevice?.id],
            })
          }
        >
          <ParentArrowIcon color={theme.colors.gray[9]} />

          <Group noWrap>
            <DeviceAvatarWithIndicator
              deviceName={parentDevice.name}
              deviceModelSettings={parentDevice.model_settings}
              deviceStatus={parentDevice.state?.status}
            />

            <div>
              <Text
                truncate
                color="gray.8"
                mb={3}
                data-testid="parent-device-name-display"
              >
                {parentDevice.name}
              </Text>
              <Text truncate color="gray.6" size="xs">
                {parentDevice.device_model_name}
              </Text>
            </div>
          </Group>
        </Group>
      )}

      <Box className={classes.container}>
        <Stack spacing="xs" align="center">
          <DeviceCecPartnerLogoOrName device={device} />

          <DeviceAvatar
            className={classes.avatar}
            img={device.model_settings?.media?.img}
            icon={device.model_settings?.icon}
            radius="lg"
            h={100}
          />
        </Stack>

        <Stack spacing="xs" justify="start" className={classes.content}>
          <DeviceName device={device} />

          <TextWithTooltip
            label={device.space_tree_path_name}
            className={classes.location}
          >
            <Anchor component={Link} to={`/overview/${device.space_id}`}>
              {device.space_tree_path_name}
            </Anchor>
          </TextWithTooltip>

          <Group p={0} align="center" sx={{ height: 30 }}>
            <DeviceStatusBadge status={device.status} />

            {device.snoozed_until ? (
              <Tooltip
                position="right"
                label={
                  <Stack spacing={0}>
                    <span>{`Incident tracking is off until ${prettyTime(
                      device.snoozed_until
                    )}`}</span>
                    <span>Click to turn it on</span>
                  </Stack>
                }
              >
                <ActionIcon
                  variant="transparent"
                  onClick={() => deviceContext.onUnsnoozeDevice()}
                  sx={(theme) => ({
                    svg: {
                      width: 17,
                      color: theme.colors.blue_gray[7],
                    },
                  })}
                >
                  <Snooze />
                </ActionIcon>
              </Tooltip>
            ) : null}
          </Group>
        </Stack>
      </Box>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
    width: '100%',
    gap: 16,
  },
  parentDevice: {
    cursor: 'pointer',
  },
  content: {
    overflow: 'hidden',
  },
  avatar: {
    width: '100%',
    height: '100%',
  },
  location: {
    fontSize: theme.fontSizes.xs,

    [theme.fn.largerThan(1600)]: {
      fontSize: theme.fontSizes.md,
    },
  },
  status: {
    fontWeight: 400,
    color: theme.colors.gray[9],
    fontSize: theme.fontSizes.xs,
  },
}));
