import {
  ActionIcon,
  Anchor,
  createStyles,
  Group,
  Indicator,
  Overlay,
  Popover,
} from '@mantine/core';
import { map, sum } from 'lodash/fp';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import { usePortalConfig } from '@portals/api/organizations';
import { ReactComponent as ShoppingCartIcon } from '@portals/icons/linear/shopping-cart.svg';

import { ExternalStoreShoppingCart } from '../../../desktop/pages/external-store/external-store-shopping-cart/ExternalStoreShoppingCart';
import { useCartProductsList } from '../../../redux/hooks/store';

interface ShopNavbarProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

export function ExternalStoreNavbar({ setIsOpen, isOpen }: ShopNavbarProps) {
  const { classes } = useStyles();

  const portalConfig = usePortalConfig();

  const cartProductsList = useCartProductsList();
  const totalCount = useMemo(
    () => sum(map('quantity', cartProductsList)),
    [cartProductsList]
  );

  useUpdateEffect(() => {
    if (totalCount > 0 && !isOpen) {
      setIsOpen(true);
    }
  }, [totalCount]);

  return (
    <Group position="apart" spacing="xl" className={classes.container}>
      <img
        width={40}
        height={40}
        alt="logo"
        src={
          portalConfig.data?.design?.store?.logo ||
          portalConfig.data?.design?.navigation_menu?.logo ||
          'logo_full_dark.svg'
        }
      />

      <Group>
        <Popover
          width="100%"
          opened={isOpen}
          onClose={() => setIsOpen(false)}
          closeOnClickOutside
          withinPortal
          position="bottom"
          shadow="xl"
        >
          <Popover.Target>
            <Indicator
              inline
              size={16}
              offset={6}
              label={totalCount}
              disabled={!totalCount}
              color="red_accent"
            >
              <ActionIcon
                variant="transparent"
                color="gray.7"
                size="lg"
                onClick={() => setIsOpen((prev) => !prev)}
              >
                <ShoppingCartIcon />
              </ActionIcon>
            </Indicator>
          </Popover.Target>

          <Popover.Dropdown p={0}>
            {isOpen && (
              <Overlay
                className={classes.overlay}
                onClick={() => setIsOpen(false)}
              />
            )}
            <div className={classes.cartContainer}>
              <ExternalStoreShoppingCart onClose={() => setIsOpen(false)} />
            </div>
          </Popover.Dropdown>
        </Popover>

        <Anchor
          component={Link}
          to="/auth/sign-in"
          className={classes.signinLink}
        >
          Sign In
        </Anchor>
      </Group>
    </Group>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    borderBottom: `1px solid ${theme.colors.blue_gray[0]}`,
    paddingBlock: theme.spacing.xs,
    paddingInline: theme.spacing.xl,
    backgroundColor: theme.white,
  },
  signinLink: {
    color: theme.colors.blue_gray[7],

    '&:hover': {
      textDecoration: 'none',
      color: theme.colors.blue_gray[7],
    },
  },
  cartContainer: {
    overflow: 'hidden',
    position: 'relative',
    zIndex: 201,
    borderBottomLeftRadius: theme.radius.lg,
    borderBottomRightRadius: theme.radius.lg,
    backgroundColor: theme.white,
  },
  overlay: {
    width: '100%',
    height: '100dvh',
  },
}));
