import { Button, Center } from '@mantine/core';
import React from 'react';

import { ModalCenteredMediaLayout } from '@portals/core';
import { messageBubblesSrc, ModalProps } from '@portals/framework';

interface ContactSupportSuccessModalProps extends ModalProps {}

export function ContactSupportSuccessModal({
  closeMe,
}: ContactSupportSuccessModalProps) {
  return (
    <ModalCenteredMediaLayout
      opened
      onClose={closeMe}
      media={<img src={messageBubblesSrc} alt="message bubbles" />}
      title="Thanks for reaching out!"
      description="Our support team will get back to you shortly"
    >
      <Center>
        <Button
          w={130}
          onClick={closeMe}
          data-testid="suggest-brand-done-button"
        >
          Done
        </Button>
      </Center>
    </ModalCenteredMediaLayout>
  );
}
