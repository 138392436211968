import {
  ActionIcon,
  Button,
  createStyles,
  Drawer,
  Stack,
  Text,
} from '@mantine/core';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DeviceDetailsType, SpaceType } from '@portals/api/organizations';
import { DeviceAvatar, DeviceStatusBadge } from '@portals/framework';
import { ReactComponent as ArrowLeft } from '@portals/icons/linear/arrow-left.svg';
import { ReactComponent as Data } from '@portals/icons/linear/data-2.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit-2.svg';
import { ReactComponent as More } from '@portals/icons/linear/more.svg';
import { useOpenModal } from '@portals/redux';

import { DeviceTabs } from './DeviceTabs';
import { DeviceTab } from '../device.types';

interface DeviceHeaderProps {
  device: DeviceDetailsType;
  space: SpaceType;
  tab: DeviceTab;
  setTab: Dispatch<SetStateAction<DeviceTab>>;
}

export function DeviceHeader({
  device,
  space,
  tab,
  setTab,
}: DeviceHeaderProps) {
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  const openModal = useOpenModal();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onBack = () => {
    if (window.history.length > 1) {
      // There is history within the same domain
      navigate(-1);
    } else {
      // No history, navigate to a specific URL
      navigate('/devices');
    }
  };

  return (
    <Stack className={classes.container} spacing="md">
      <DeviceAvatar
        img={device.model_settings?.media?.img}
        icon={device.model_settings?.icon}
        size={68}
      />

      <Stack spacing="xs" align="center">
        <Text
          size="lg"
          color="gray.9"
          weight="500"
          data-testid={`device-name-${device.name}`}
        >
          {device.name}
        </Text>

        <Text size="sm" color="gray.6" data-testid={`space-name-${space.name}`}>
          {space.name}
        </Text>
      </Stack>

      <DeviceStatusBadge status={device.status} />

      <DeviceTabs device={device} tab={tab} setTab={setTab} />

      <ActionIcon
        onClick={onBack}
        className={classes.actionButton}
        style={{
          left: theme.spacing.xl,
        }}
      >
        <ArrowLeft width={24} height={24} color={theme.colors.gray[9]} />
      </ActionIcon>

      <ActionIcon
        onClick={() => setIsDrawerOpen(true)}
        className={classes.actionButton}
        style={{
          right: theme.spacing.xl,
        }}
      >
        <More width={24} height={24} color={theme.colors.gray[9]} />
      </ActionIcon>

      <Drawer
        withinPortal
        opened={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        withCloseButton={false}
        position="bottom"
        size="auto"
        styles={{
          content: {
            borderTopLeftRadius: `${theme.radius.lg} !important`,
            borderTopRightRadius: `${theme.radius.lg} !important`,
          },
        }}
      >
        <Stack p="md" spacing="md">
          <Button
            variant="white"
            leftIcon={<Edit style={{ marginRight: 8 }} />}
            color="gray.9"
            onClick={() => {
              setIsDrawerOpen(false);
              openModal('UpdateDeviceName', { device });
            }}
            styles={{
              inner: {
                justifyContent: 'left',
              },
            }}
          >
            Change name
          </Button>

          <Button
            variant="white"
            leftIcon={<Data style={{ marginRight: 8 }} />}
            color="gray.9"
            onClick={() => {
              setIsDrawerOpen(false);
              openModal('MoveDevice', { device });
            }}
            styles={{
              inner: {
                justifyContent: 'left',
              },
            }}
          >
            Move device
          </Button>
        </Stack>
      </Drawer>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    padding: `${theme.spacing.xl} ${theme.spacing.md}`,
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    background: theme.white,
    zIndex: 1,
  },
  actionButton: {
    position: 'absolute',
    top: theme.spacing.xxl,
  },
}));
