import twoN_intercomSrc from '../../assets/c2c-integrations/2n_intercom_vendor_connect.svg';
import twoNSrc from '../../assets/c2c-integrations/2n_vendor_connect.svg';
import fourSuitesSrc from '../../assets/c2c-integrations/4suites_vendor_connect.svg';
import assaSrc from '../../assets/c2c-integrations/assa_vendor_connect.svg';
import avigilonSrc from '../../assets/c2c-integrations/avigilon_vendor_connect.svg';
import barcoSrc from '../../assets/c2c-integrations/barco_vendor_connect.svg';
import biampSrc from '../../assets/c2c-integrations/biamp_vendor_connect.svg';
import brivoSrc from '../../assets/c2c-integrations/brivo_vendor_connect.svg';
import controlbywebSrc from '../../assets/c2c-integrations/controlbyweb_vendor_connect.svg';
import honeywellSrc from '../../assets/c2c-integrations/honeywell_vendor_connect.svg';
import intuneSrc from '../../assets/c2c-integrations/intune_vendor_connect.svg';
import lenelSrc from '../../assets/c2c-integrations/lenel_vendor_connect.svg';
import logitechSrc from '../../assets/c2c-integrations/logitech_vendor_connect.svg';
import mtrSrc from '../../assets/c2c-integrations/mtr_vendor_connect.svg';
import neatSrc from '../../assets/c2c-integrations/neat_vendor_connect.svg';
import novastarSrc from '../../assets/c2c-integrations/novastar_vendor_connect.svg';
import polySrc from '../../assets/c2c-integrations/poly_vendor_connect.svg';
import ptiSrc from '../../assets/c2c-integrations/pti_vendor_connect.svg';
import q_sysSrc from '../../assets/c2c-integrations/q_sys_vendor_connect.svg';
import qscSrc from '../../assets/c2c-integrations/qsc_vendor_connect.svg';
import saltoSrc from '../../assets/c2c-integrations/salto_vendor_connect.svg';
import visionlineSrc from '../../assets/c2c-integrations/visionline_vendor_connect.svg';
import webexSrc from '../../assets/c2c-integrations/webex_vendor_connect.svg';
import xioSrc from '../../assets/c2c-integrations/xio_vendor_connect.svg';
import yaleSrc from '../../assets/c2c-integrations/yale_vendor_connect.svg';
import yealinkSrc from '../../assets/c2c-integrations/yealink_vendor_connect.svg';
import zoomSrc from '../../assets/c2c-integrations/zoom_vendor_connect.svg';

export type C2CIntegrationId =
  | 'zoom'
  | 'mtr'
  | 'xio'
  | 'q_sys'
  | 'logitech'
  | 'webex'
  | 'poly'
  | 'neat'
  | 'qsc'
  | 'novastar'
  | 'yale'
  | '2n'
  | 'honeywell'
  | 'lenel'
  | 'assa'
  | 'intune'
  | 'biamp'
  | 'yealink'
  | 'barco'
  | 'visionline'
  | 'avigilon'
  | 'brivo'
  | 'controlbyweb'
  | '4suites'
  | 'pti'
  | 'salto'
  | '2n_intercom';

export interface C2CIntegrationItem {
  id: C2CIntegrationId;
  logo: string;
  name: string;
  description: string;
}

export const C2C_INTEGRATIONS = {
  mtr: {
    id: 'mtr',
    name: 'Microsoft Teams Rooms',
    logo: mtrSrc,
    description:
      'Microsoft Teams Rooms optimize meetings with integrated video conferencing, making collaboration in workspaces more efficient and engaging.',
  },
  zoom: {
    id: 'zoom',
    name: 'Zoom Rooms',
    logo: zoomSrc,
    description:
      'Zoom Rooms deliver seamless video conferencing experiences, transforming any space into a modern, collaborative meeting environment.',
  },
  xio: {
    id: 'xio',
    name: 'Crestron XiO Cloud',
    logo: xioSrc,
    description:
      'Crestron XiO Cloud provides remote management and monitoring of devices, streamlining IT operations across workspaces.',
  },
  logitech: {
    id: 'logitech',
    name: 'Logitech',
    logo: logitechSrc,
    description:
      'Logitech creates innovative peripherals and video conferencing solutions that enhance productivity, perfect for modern work environments.',
  },
  q_sys: {
    id: 'q_sys',
    name: 'Reflect',
    logo: q_sysSrc,
    description:
      'QSC Reflect offers cloud-based management and monitoring for AV systems, ensuring reliable performance and easy control in various environments.',
  },
  webex: {
    id: 'webex',
    name: 'Cisco Webex',
    logo: webexSrc,
    description:
      'Cisco Webex provides a robust video conferencing solution, turning any room into a connected, collaborative meeting environment.',
  },
  poly: {
    id: 'poly',
    name: 'Poly',
    logo: polySrc,
    description:
      'Poly enables immersive video conferencing, bringing modern collaboration experiences to any meeting space.',
  },
  neat: {
    id: 'neat',
    name: 'Neat',
    logo: neatSrc,
    description:
      'Neat redefines video conferencing, creating seamless, intuitive communication experiences in any room.',
  },
  qsc: {
    id: 'qsc',
    name: 'QSC',
    logo: qscSrc,
    description:
      'QSC offers high-quality AV solutions, enhancing any space with crystal-clear audio and video communication.',
  },
  novastar: {
    id: 'novastar',
    name: 'Novastar',
    logo: novastarSrc,
    description:
      'Novastar revolutionizes LED display technology, transforming spaces into visually stunning, collaborative environments.',
  },
  yale: {
    id: 'yale',
    name: 'Yale',
    logo: yaleSrc,
    description:
      'Yale delivers smart security solutions, making any building safer and more connected.',
  },
  '2n': {
    id: '2n',
    name: '2N',
    logo: twoNSrc,
    description:
      '2N offers advanced intercom systems, transforming building communication with cutting-edge security and control.',
  },
  honeywell: {
    id: 'honeywell',
    name: 'Honeywell',
    logo: honeywellSrc,
    description:
      'Honeywell provides integrated security solutions, ensuring safety and seamless building management.',
  },
  lenel: {
    id: 'lenel',
    name: 'Lenel',
    logo: lenelSrc,
    description:
      'Lenel delivers powerful security systems, modernizing access control and enhancing building safety.',
  },
  assa: {
    id: 'assa',
    name: 'Assa',
    logo: assaSrc,
    description:
      'Assa offers innovative locking systems, transforming any facility into a secure, smart environment.',
  },
  intune: {
    id: 'intune',
    name: 'Microsoft Intune',
    logo: intuneSrc,
    description:
      'Microsoft Intune provides robust device management solutions, ensuring secure and efficient enterprise environments.',
  },
  biamp: {
    id: 'biamp',
    name: 'Biamp',
    logo: biampSrc,
    description:
      'Biamp offers world-class audio systems, transforming any space into a clear, connected communication hub.',
  },
  yealink: {
    id: 'yealink',
    name: 'Yealink',
    logo: yealinkSrc,
    description:
      'Yealink provides intuitive video conferencing solutions, turning any room into a collaborative meeting environment.',
  },
  barco: {
    id: 'barco',
    name: 'Barco',
    logo: barcoSrc,
    description:
      'Barco delivers innovative visualization and collaboration tools, transforming spaces into interactive, connected hubs.',
  },
  visionline: {
    id: 'visionline',
    name: 'ASSA ABLOY Visionline',
    logo: visionlineSrc,
    description:
      'ASSA ABLOY Visionline offers advanced access control, modernizing building security with seamless integration.',
  },
  avigilon: {
    id: 'avigilon',
    name: 'Avigilon Access Control',
    logo: avigilonSrc,
    description:
      'Avigilon provides cutting-edge access control systems, transforming any facility into a secure, streamlined environment.',
  },
  brivo: {
    id: 'brivo',
    name: 'Brivo Access Control',
    logo: brivoSrc,
    description:
      'Brivo delivers cloud-based access control, making any building safer and more connected.',
  },
  controlbyweb: {
    id: 'controlbyweb',
    name: 'ControlByWeb',
    logo: controlbywebSrc,
    description:
      'ControlByWeb offers advanced web-based monitoring, transforming facility management with real-time control and visibility.',
  },
  '4suites': {
    id: '4suites',
    name: '4Suites Locks',
    logo: fourSuitesSrc,
    description:
      '4Suites provides smart lock solutions, making any facility secure and effortlessly connected.',
  },
  pti: {
    id: 'pti',
    name: 'PTI Security Systems',
    logo: ptiSrc,
    description:
      'PTI delivers advanced security systems, transforming storage facilities into secure, controlled environments.',
  },
  salto: {
    id: 'salto',
    name: 'Salto Locks',
    logo: saltoSrc,
    description:
      'Salto offers smart locking solutions, revolutionizing access control in any modern facility.',
  },
  '2n_intercom': {
    id: '2n_intercom',
    name: '2N Intercom',
    logo: twoN_intercomSrc,
    description:
      '2N Intercom systems offer advanced building communication, ensuring secure and efficient access control.',
  },
} as const satisfies Record<C2CIntegrationId, C2CIntegrationItem>;
