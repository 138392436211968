import { createStyles, Group, Stack, Text } from '@mantine/core';
import React from 'react';

import { DeviceModelSearchResultType } from '@portals/api/organizations';
import { DeviceAvatar } from '@portals/framework';

interface DeviceModelSearchResultProps {
  deviceModel: DeviceModelSearchResultType;
  onDeviceModelSelected: (searchResult: DeviceModelSearchResultType) => void;
}

export function DeviceModelSearchResult({
  deviceModel,
  onDeviceModelSelected,
}: DeviceModelSearchResultProps) {
  const { classes } = useStyles();

  return (
    <Stack spacing="xs" className={classes.container}>
      <Group
        p="xs"
        onClick={() => onDeviceModelSelected(deviceModel)}
        sx={{ cursor: 'pointer' }}
      >
        <DeviceAvatar
          img={deviceModel.user_settings?.media?.img}
          icon={deviceModel.user_settings?.icon}
          w={46}
          h={46}
          radius="md"
        />

        <div>
          <Text weight={500}>{deviceModel.name}</Text>
          <Text weight={500} color="gray.5" size="xs">
            By {deviceModel.partner_display_name}
          </Text>
        </div>
      </Group>
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    borderRadius: theme.radius.md,

    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
}));
